_cruxUtils._cruxLocale = {
"crux.no.bc.message":"\u0e44\u0e21\u0e48\u0e21\u0e35\u0e20\u0e32\u0e1e\u0e23\u0e27\u0e21 \u0e04\u0e25\u0e34\u0e01\u0e17\u0e35\u0e48\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e1e\u0e37\u0e48\u0e2d\u0e14\u0e39\u0e23\u0e32\u0e22\u0e25\u0e30\u0e40\u0e2d\u0e35\u0e22\u0e14\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21",
"crm.appointments.status.markcompleted1": "\u0e17\u0e33\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e2b\u0e21\u0e32\u0e22\u0e40\u0e1b\u0e47\u0e19\u0e40\u0e2a\u0e23\u0e47\u0e08\u0e2a\u0e21\u0e1a\u0e39\u0e23\u0e13\u0e4c",
"crm.mass.actions.all.selected": "<span class=\"dIB vaM\">\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u00a0</span><span class='mL3 mR3 crm-font-bold dIB vaM recordCountHt'> {0}\u00a0</span><span class=\"dIB vaM\"> {1} \u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14\u0e43\u0e19\u0e21\u0e38\u0e21\u0e21\u0e2d\u0e07\u0e19\u0e35\u0e49\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14\u0e16\u0e39\u0e01\u0e40\u0e25\u0e37\u0e2d\u0e01</span>",
"crm.module.selectall": "\u0e40\u0e25\u0e37\u0e2d\u0e01 {0} \u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14\u0e43\u0e19\u0e21\u0e38\u0e21\u0e21\u0e2d\u0e07\u0e19\u0e35\u0e49",
"records": "\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19",
"crm.record.not.accessible": "\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19",
"crm.view.calls.recording.err.msg": "\u0e01\u0e32\u0e23\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e40\u0e2a\u0e35\u0e22\u0e07\u0e44\u0e21\u0e48\u0e21\u0e35 URL \u0e40\u0e2a\u0e35\u0e22\u0e07\u0e17\u0e35\u0e48\u0e40\u0e25\u0e48\u0e19\u0e44\u0e14\u0e49",
"crm.listview.selected.records": "<span class='mL3 crm-small-font-size crm-font-bold vaM dIB'>{0} </span><span class=\"mL3 seletcedRecords\">\u00a0{1} \u0e16\u0e39\u0e01\u0e40\u0e25\u0e37\u0e2d\u0e01</span>",
"totalrecords": "\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",
"crm.listview.options.text.clip": "\u0e04\u0e25\u0e34\u0e1b\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21",
"crm.listview.options.text.wrap": "\u0e15\u0e31\u0e14\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21",
"crm.listview.options.reset.width": "\u0e23\u0e35\u0e40\u0e0b\u0e47\u0e15\u0e02\u0e19\u0e32\u0e14\u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c",
"crm.listview.options.manage": "\u0e08\u0e31\u0e14\u0e01\u0e32\u0e23\u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c",
"crm.listview.customview.locked": "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e1b\u0e23\u0e31\u0e1a\u0e41\u0e15\u0e48\u0e07\u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c\u0e40\u0e19\u0e37\u0e48\u0e2d\u0e07\u0e08\u0e32\u0e01\u0e21\u0e38\u0e21\u0e21\u0e2d\u0e07\u0e19\u0e35\u0e49\u0e16\u0e39\u0e01\u0e25\u0e47\u0e2d\u0e01",
"crm.api.apidashboard.SortBy": "\u0e40\u0e23\u0e35\u0e22\u0e07\u0e15\u0e32\u0e21",
"crm.privacy.listview.consent.locked": "\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e16\u0e39\u0e01\u0e25\u0e47\u0e2d\u0e01\u0e44\u0e21\u0e48\u0e43\u0e2b\u0e49\u0e1b\u0e23\u0e30\u0e21\u0e27\u0e25\u0e1c\u0e25",
"crm.label.reschedule.call": "\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e01\u0e32\u0e23\u0e43\u0e2b\u0e21\u0e48\u0e02\u0e2d\u0e07 {0}",
"crm.button.edit": "\u0e41\u0e01\u0e49\u0e44\u0e02",
"Delete": "\u0e25\u0e1a",
"crm.appointments.status.markcancel": "\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01 {0}",
"crm.appointments.status.markreschedule": "\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e01\u0e32\u0e23\u0e43\u0e2b\u0e21\u0e48\u0e02\u0e2d\u0e07 {0}",
"crm.label.cancel.call": "\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01 {0}",
"crm.label.mark.completed": "\u0e17\u0e33\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e2b\u0e21\u0e32\u0e22\u0e40\u0e1b\u0e47\u0e19\u0e40\u0e2a\u0e23\u0e47\u0e08\u0e2a\u0e21\u0e1a\u0e39\u0e23\u0e13\u0e4c",
"crm.listview.customview.locked": "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e1b\u0e23\u0e31\u0e1a\u0e41\u0e15\u0e48\u0e07\u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c\u0e40\u0e19\u0e37\u0e48\u0e2d\u0e07\u0e08\u0e32\u0e01\u0e21\u0e38\u0e21\u0e21\u0e2d\u0e07\u0e19\u0e35\u0e49\u0e16\u0e39\u0e01\u0e25\u0e47\u0e2d\u0e01",
"pf.deleted.state": "\u0e25\u0e1a\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e41\u0e25\u0e49\u0e27",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "\u0e0b\u0e34\u0e07\u0e04\u0e4c\u0e01\u0e31\u0e1a {0}",
"crm.schedules.prefix.in": "\u0e43\u0e19",
"crm.record.record.locked.other": "\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e16\u0e39\u0e01\u0e25\u0e47\u0e2d\u0e01\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e43\u0e19\u0e42\u0e1b\u0e23\u0e44\u0e1f\u0e25\u0e4c\u0e2d\u0e37\u0e48\u0e19",
"crm.record.lock.record.locked": "\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e16\u0e39\u0e01\u0e25\u0e47\u0e2d\u0e01",
"crm.approvalProcess.label.waitingForApproval": "\u0e01\u0e33\u0e25\u0e31\u0e07\u0e23\u0e2d\u0e01\u0e32\u0e23\u0e2d\u0e19\u0e38\u0e21\u0e31\u0e15\u0e34",
"crm.reviewprocess.record.review.pending": "\u0e23\u0e2d\u0e01\u0e32\u0e23\u0e17\u0e1a\u0e17\u0e27\u0e19",
"crm.approvalProcess.label.waitingForFindAndMerge": "\u0e01\u0e32\u0e23\u0e1c\u0e2a\u0e32\u0e19\u0e02\u0e2d\u0e07\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e0b\u0e49\u0e33\u0e01\u0e33\u0e25\u0e31\u0e07\u0e04\u0e37\u0e1a\u0e2b\u0e19\u0e49\u0e32",
"crm.privacy.listview.consent.locked": "\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e16\u0e39\u0e01\u0e25\u0e47\u0e2d\u0e01\u0e44\u0e21\u0e48\u0e43\u0e2b\u0e49\u0e1b\u0e23\u0e30\u0e21\u0e27\u0e25\u0e1c\u0e25",
"crm.zia.vision.processing": "Zia \u0e01\u0e33\u0e25\u0e31\u0e07\u0e15\u0e23\u0e27\u0e08\u0e2a\u0e2d\u0e1a\u0e04\u0e27\u0e32\u0e21\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e02\u0e2d\u0e07\u0e20\u0e32\u0e1e",
"crm.zia.vision.rejected.msg": "\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e16\u0e39\u0e01\u0e1b\u0e0f\u0e34\u0e40\u0e2a\u0e18\u0e40\u0e19\u0e37\u0e48\u0e2d\u0e07\u0e08\u0e32\u0e01\u0e44\u0e21\u0e48\u0e1c\u0e48\u0e32\u0e19\u0e01\u0e32\u0e23\u0e15\u0e23\u0e27\u0e08\u0e2a\u0e2d\u0e1a\u0e04\u0e27\u0e32\u0e21\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e02\u0e2d\u0e07\u0e20\u0e32\u0e1e",
"crm.zia.vision.record.failure.msg": "\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e23\u0e2d\u0e01\u0e32\u0e23\u0e2d\u0e19\u0e38\u0e21\u0e31\u0e15\u0e34\u0e40\u0e19\u0e37\u0e48\u0e2d\u0e07\u0e08\u0e32\u0e01\u0e44\u0e21\u0e48\u0e1c\u0e48\u0e32\u0e19\u0e01\u0e32\u0e23\u0e15\u0e23\u0e27\u0e08\u0e2a\u0e2d\u0e1a\u0e04\u0e27\u0e32\u0e21\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e02\u0e2d\u0e07\u0e20\u0e32\u0e1e",
"crm.alert.max.cvcols.reached": "\u0e04\u0e38\u0e13\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e14\u0e49\u0e44\u0e21\u0e48\u0e40\u0e01\u0e34\u0e19 {0} \u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c",
"crm.label.small.tags": "\u0e41\u0e17\u0e47\u0e01",
"crm.label.edit.module": "\u0e41\u0e01\u0e49\u0e44\u0e02 {0}",
"crm.social.integ.no.result": "\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e1c\u0e25\u0e25\u0e31\u0e1e\u0e18\u0e4c",
"custommodule.crmfield.fl.name": "\u0e0a\u0e37\u0e48\u0e2d {0}",
"Appointment": "\u0e01\u0e32\u0e23\u0e19\u0e31\u0e14\u0e2b\u0e21\u0e32\u0e22",
"crm.button.create.call": "\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23",
"crm.module.create": "\u0e2a\u0e23\u0e49\u0e32\u0e07 {0}",
"crm.customview.activty.badge": "\u0e1b\u0e49\u0e32\u0e22\u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21",
"crm.button.callnow": "\u0e42\u0e17\u0e23\u0e15\u0e2d\u0e19\u0e19\u0e35\u0e49",
"crm.manualcalllist.complete.call": "\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23",
"crm.label.schedule.call": "\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e01\u0e32\u0e23\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23",
"crm.filter.label.replied":"\u0e15\u0e2d\u0e1a\u0e01\u0e25\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",
"bytes": "\u0e44\u0e1a\u0e15\u0e4c",
"crm.field.label.user.lookup": "\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",
"crm.translation.refresh.cases": "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e17\u0e33\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e17\u0e35\u0e48\u0e04\u0e38\u0e13\u0e1e\u0e22\u0e32\u0e22\u0e32\u0e21\u0e17\u0e33\u0e43\u0e2b\u0e49\u0e40\u0e2a\u0e23\u0e47\u0e08\u0e2a\u0e34\u0e49\u0e19\u0e44\u0e14\u0e49 \u0e42\u0e1b\u0e23\u0e14\u0e23\u0e35\u0e40\u0e1f\u0e23\u0e0a\u0e2b\u0e19\u0e49\u0e32\u0e41\u0e25\u0e49\u0e27\u0e25\u0e2d\u0e07\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07",
"crm.project.sync.notes.info": "\u0e02\u0e49\u0e2d\u0e04\u0e34\u0e14\u0e40\u0e2b\u0e47\u0e19\u0e02\u0e2d\u0e07\u0e07\u0e32\u0e19\u0e02\u0e2d\u0e07 Projects \u0e17\u0e35\u0e48\u0e40\u0e0a\u0e37\u0e48\u0e2d\u0e21\u0e42\u0e22\u0e07\u0e41\u0e25\u0e49\u0e27\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e2a\u0e14\u0e07\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",
"crux.note.content.limit.warning.msg":"\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e43\u0e2b\u0e49\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01 {0} \u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19\u0e43\u0e19\u0e40\u0e19\u0e37\u0e49\u0e2d\u0e2b\u0e32\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d",
"crux.note.formatting.options":"\u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e01\u0e32\u0e23\u0e08\u0e31\u0e14\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a",
"crm.label.required": "\u0e08\u0e33\u0e40\u0e1b\u0e47\u0e19",
"crux.condition.not.belongs.to":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e40\u0e1b\u0e47\u0e19\u0e02\u0e2d\u0e07 {0}",
"crm.project.sync.notes.info": "\u0e02\u0e49\u0e2d\u0e04\u0e34\u0e14\u0e40\u0e2b\u0e47\u0e19\u0e02\u0e2d\u0e07\u0e07\u0e32\u0e19\u0e02\u0e2d\u0e07 Projects \u0e17\u0e35\u0e48\u0e40\u0e0a\u0e37\u0e48\u0e2d\u0e21\u0e42\u0e22\u0e07\u0e41\u0e25\u0e49\u0e27\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e2a\u0e14\u0e07\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",
"crm.sf.permission.no.edit": "\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e21\u0e35\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e4c\u0e43\u0e19\u0e01\u0e32\u0e23\u0e2d\u0e31\u0e1b\u0e40\u0e14\u0e15\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",
"crm.sf.permission.no.create": "\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e21\u0e35\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e4c\u0e43\u0e19\u0e01\u0e32\u0e23\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",
"crm.sf.permission.no.delete": "\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e21\u0e35\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e4c\u0e43\u0e19\u0e01\u0e32\u0e23\u0e25\u0e1a\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",
"crux.note.this.note":"\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d\u0e19\u0e35\u0e49",
"KB": "KB",
"MB": "MB",
"current.logged.in.user.role": "\u0e44\u0e14\u0e49\u0e40\u0e02\u0e49\u0e32\u0e2a\u0e39\u0e48\u0e23\u0e30\u0e1a\u0e1a\u0e43\u0e19\u0e1a\u0e17\u0e1a\u0e32\u0e17\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",
"Unit Price": "\u0e23\u0e32\u0e04\u0e32\u0e15\u0e48\u0e2d\u0e2b\u0e19\u0e48\u0e27\u0e22",
"Qty in Stock": "\u0e1b\u0e23\u0e34\u0e21\u0e32\u0e13\u0e43\u0e19\u0e2a\u0e15\u0e47\u0e2d\u0e01",
"crm.label.Product Code": "\u0e23\u0e2b\u0e31\u0e2a\u0e1c\u0e25\u0e34\u0e15\u0e20\u0e31\u0e13\u0e11\u0e4c",
"TotalAfterDiscount": "\u0e1c\u0e25\u0e23\u0e27\u0e21\u0e2b\u0e25\u0e31\u0e07\u0e2b\u0e31\u0e01\u0e2a\u0e48\u0e27\u0e19\u0e25\u0e14",
"crm.lookupfilter.entity.errormsg": "{0} \u0e17\u0e35\u0e48\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e21\u0e48\u0e15\u0e23\u0e07\u0e15\u0e32\u0e21\u0e40\u0e01\u0e13\u0e11\u0e4c\u0e02\u0e2d\u0e07\u0e01\u0e32\u0e23\u0e04\u0e49\u0e19\u0e2b\u0e32",
"crm.iamexception.maxlen": "\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e1b\u0e49\u0e2d\u0e19\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {0} \u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a {1}",
"crm.record.locking.permission.denied": "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e17\u0e33\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e19\u0e35\u0e49\u0e44\u0e14\u0e49 \u0e40\u0e19\u0e37\u0e48\u0e2d\u0e07\u0e08\u0e32\u0e01\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e16\u0e39\u0e01\u0e25\u0e47\u0e2d\u0e01",
"List Price": "\u0e23\u0e32\u0e04\u0e32\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",
"crm.label.no.more.records": "\u0e44\u0e21\u0e48\u0e21\u0e35\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e2d\u0e35\u0e01",
"crm.workflow.rule.created.dateAndTime":"{0} \u0e43\u0e19 {1} \u0e17\u0e35\u0e48 {2}",
"crux.new.note.msg":"\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d\u0e19\u0e35\u0e49\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e01\u0e31\u0e1a\u0e2d\u0e30\u0e44\u0e23",
"crm.image.uploaded":"\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e\u0e41\u0e25\u0e49\u0e27",
"crm.imageupload.drag.drop.here":"\u0e25\u0e32\u0e01\u0e41\u0e25\u0e30\u0e27\u0e32\u0e07\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e\u0e17\u0e35\u0e48\u0e19\u0e35\u0e48",
"crm.fileupload.drag.drop.here":"\u0e25\u0e32\u0e01\u0e41\u0e25\u0e30\u0e27\u0e32\u0e07\u0e44\u0e1f\u0e25\u0e4c\u0e17\u0e35\u0e48\u0e19\u0e35\u0e48",
"crm.fileupload.wait.msg":"\u0e42\u0e1b\u0e23\u0e14\u0e23\u0e2d\u0e08\u0e19\u0e01\u0e27\u0e48\u0e32\u0e44\u0e1f\u0e25\u0e4c\u0e17\u0e35\u0e48\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e08\u0e30\u0e16\u0e39\u0e01\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14",
"crm.fileupload.attach.fail":"\u0e01\u0e32\u0e23\u0e41\u0e19\u0e1a\u0e25\u0e49\u0e21\u0e40\u0e2b\u0e25\u0e27",
"crm.fileupload.support.format":"\u0e23\u0e2d\u0e07\u0e23\u0e31\u0e1a\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e44\u0e1f\u0e25\u0e4c (jpeg, jpg, png, pdf) \u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e01\u0e32\u0e23\u0e1d\u0e36\u0e01\u0e1d\u0e19\u0e02\u0e2d\u0e07 Zia",
"custmr.prtl.user.role": "\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e02\u0e2d\u0e07\u0e1e\u0e2d\u0e23\u0e4c\u0e17\u0e31\u0e25",
"crm.subform.empty.alert.header":"\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e41\u0e16\u0e27\u0e17\u0e35\u0e48\u0e27\u0e48\u0e32\u0e07\u0e40\u0e1b\u0e25\u0e48\u0e32",
"crm.crud.lookup.module.inaccessible": "\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e21\u0e35\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e4c\u0e43\u0e19\u0e01\u0e32\u0e23\u0e40\u0e02\u0e49\u0e32\u0e16\u0e36\u0e07\u0e21\u0e2d\u0e14\u0e39\u0e25\u0e01\u0e32\u0e23\u0e04\u0e49\u0e19\u0e2b\u0e32\u0e19\u0e35\u0e49",
"crm.crud.lookup.inaccessible.record": "\u0e44\u0e21\u0e48\u0e21\u0e35\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e2b\u0e23\u0e37\u0e2d\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e02\u0e49\u0e32\u0e16\u0e36\u0e07\u0e44\u0e14\u0e49",
"crm.custombutton.valid.weburl.check": "\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19\u0e04\u0e48\u0e32\u0e08\u0e33\u0e19\u0e27\u0e19\u0e2b\u0e19\u0e36\u0e48\u0e07",
"crm.label.subform.goto.top": "\u0e44\u0e1b\u0e17\u0e35\u0e48\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e19\u0e02\u0e2d\u0e07\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",
"crm.label.subform.row.show.all": "\u0e41\u0e2a\u0e14\u0e07\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14 ({0})",
"cpq.pr.nodiscount": "\u0e44\u0e21\u0e35\u0e21\u0e35\u0e2a\u0e48\u0e27\u0e19\u0e25\u0e14\u0e17\u0e35\u0e48\u0e40\u0e0a\u0e37\u0e48\u0e2d\u0e21\u0e42\u0e22\u0e07",
"crm.inventory.lineitem.no.pricebook.new": "\u0e44\u0e21\u0e48\u0e21\u0e35 {0} \u0e17\u0e35\u0e48\u0e40\u0e0a\u0e37\u0e48\u0e2d\u0e21\u0e42\u0e22\u0e07",
"crm.label.subform.allowedlimit": "\u0e16\u0e36\u0e07\u0e02\u0e35\u0e14\u0e08\u0e33\u0e01\u0e31\u0e14\u0e17\u0e35\u0e48\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15 {0} \u0e41\u0e16\u0e27\u0e41\u0e25\u0e49\u0e27",
"crm.reports.integ.okay": "\u0e1e\u0e2d\u0e43\u0e0a\u0e49",
"crm.crud.subform.deleted.record": "\u0e41\u0e16\u0e27\u0e02\u0e2d\u0e07\u0e41\u0e1a\u0e1a\u0e1f\u0e2d\u0e23\u0e4c\u0e21\u0e22\u0e48\u0e2d\u0e22\u0e17\u0e35\u0e48\u0e2d\u0e31\u0e1b\u0e40\u0e14\u0e15\u0e16\u0e39\u0e01\u0e25\u0e1a\u0e40\u0e23\u0e35\u0e22\u0e1a\u0e23\u0e49\u0e2d\u0e22\u0e41\u0e25\u0e49\u0e27 \u0e42\u0e1b\u0e23\u0e14\u0e23\u0e35\u0e40\u0e1f\u0e23\u0e0a\u0e2b\u0e19\u0e49\u0e32\u0e41\u0e25\u0e49\u0e27\u0e25\u0e2d\u0e07\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07",
"crux.criteria.fieldlabel.valid.check":"\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19\u0e1b\u0e49\u0e32\u0e22\u0e0a\u0e37\u0e48\u0e2d\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07",
"crm.subform.delete.confirm.header":"ลบแถวแบบฟอร์มย่อย",
"crm.subform.delete.confirm.msg": "คุณแน่ใจหรือไม่ว่าต้องการลบแถวที่เลือกใน {0}",
"crm.subform.delete.tooltip.msg" :"ไม่สามารถลบแถว ต้องมีรายการหนึ่งแถวอย่างน้อยหนึ่งรายการสำหรับ {0}",
	"crm.condition.belongs.to":"\u0e40\u0e1b\u0e47\u0e19\u0e02\u0e2d\u0e07 {0}",//no i18n
"crm.condition.not.belongs.to":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e40\u0e1b\u0e47\u0e19\u0e02\u0e2d\u0e07 {0}",//no i18n
"crm.orchestration.error.selectvalue": "\u0e42\u0e1b\u0e23\u0e14\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e04\u0e48\u0e32",
	"crm.attach.upload.image":"\u0e41\u0e19\u0e1a\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e",//no i18n
"crm.general.select.photo":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e20\u0e32\u0e1e",//no i18n
"crm.imageupload.wait.msg":"\u0e42\u0e1b\u0e23\u0e14\u0e23\u0e2d\u0e08\u0e19\u0e01\u0e27\u0e48\u0e32\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e\u0e17\u0e35\u0e48\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e08\u0e30\u0e16\u0e39\u0e01\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14",//no i18n
"crm.image.filepicker.desc.draganddrop":"\u0e25\u0e32\u0e01\u0e41\u0e25\u0e30\u0e27\u0e32\u0e07\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e",//no i18n
"crm.image.filepicker.desc.browse":"\u0e04\u0e25\u0e34\u0e01\u0e40\u0e1e\u0e37\u0e48\u0e2d\u0e40\u0e23\u0e35\u0e22\u0e01\u0e14\u0e39\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e...",//no i18n
"crm.attach.upload.userinfo":"\u0e02\u0e19\u0e32\u0e14\u0e42\u0e14\u0e22\u0e23\u0e27\u0e21\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14\u0e16\u0e39\u0e01\u0e08\u0e33\u0e01\u0e31\u0e14\u0e44\u0e27\u0e49\u0e17\u0e35\u0e48 <span class",//no i18n
"crm.image.supported.formats":"\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e17\u0e35\u0e48\u0e23\u0e2d\u0e07\u0e23\u0e31\u0e1a: JPEG, PNG, GIF \u0e41\u0e25\u0e30 BMP",//no i18n
"crm.imageupload.allowed.field.length":"\u0e04\u0e38\u0e13\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e\u0e44\u0e14\u0e49\u0e2a\u0e39\u0e07\u0e2a\u0e38\u0e14 {0} \u0e23\u0e39\u0e1b",//no i18n
"crm.general.crop.and.set":"\u0e04\u0e23\u0e2d\u0e1a\u0e15\u0e31\u0e14\u0e41\u0e25\u0e30\u0e15\u0e31\u0e49\u0e07\u0e04\u0e48\u0e32",//no i18n
"crm.attach.option.label":"\u0e41\u0e19\u0e1a",//no i18n
"crm.image.crop.and.rotate":"\u0e04\u0e23\u0e2d\u0e1a\u0e15\u0e31\u0e14\u0e41\u0e25\u0e30\u0e2b\u0e21\u0e38\u0e19",//no i18n
"crm.image.resolution":"\u0e04\u0e27\u0e32\u0e21\u0e25\u0e30\u0e40\u0e2d\u0e35\u0e22\u0e14",//no i18n
"crm.attachment.size":"\u0e02\u0e19\u0e32\u0e14",//no i18n
"crm.label.close":"\u0e1b\u0e34\u0e14",//no i18n
"crm.label.previous":"\u0e01\u0e48\u0e2d\u0e19\u0e2b\u0e19\u0e49\u0e32",//no i18n
"crm.label.next":"\u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
"crm.subform.record.create.maxrow.limit": "\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e43\u0e2b\u0e49\u0e21\u0e35\u0e2a\u0e39\u0e07\u0e2a\u0e38\u0e14 {0} \u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e43\u0e19 {1}",
"crm.label.subform.addrows": "\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e41\u0e16\u0e27",
"crm.tax.association.check": "\u0e44\u0e21\u0e48\u0e21\u0e35\u0e20\u0e32\u0e29\u0e35\u0e17\u0e35\u0e48\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e02\u0e49\u0e2d\u0e07\u0e01\u0e31\u0e1a\u0e1c\u0e25\u0e34\u0e15\u0e20\u0e31\u0e13\u0e11\u0e4c\u0e19\u0e35\u0e49",
	"crm.gallery.inventory.template.discount": "\u0e2a\u0e48\u0e27\u0e19\u0e25\u0e14",
	"crm.inventory.discount.scheme.range": "\u0e0a\u0e48\u0e27\u0e07",
	"Description": "\u0e04\u0e33\u0e2d\u0e18\u0e34\u0e1a\u0e32\u0e22",
	"crm.inventory.lineitem.no.pricebook": "\u0e44\u0e21\u0e48\u0e21\u0e35\u0e2a\u0e21\u0e38\u0e14\u0e23\u0e32\u0e04\u0e32\u0e17\u0e35\u0e48\u0e40\u0e0a\u0e37\u0e48\u0e2d\u0e21\u0e42\u0e22\u0e07",
	"crm.recordImage.previewimage":"\u0e41\u0e2a\u0e14\u0e07\u0e15\u0e31\u0e27\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e20\u0e32\u0e1e",//no i18n
"crm.FileuploadField.addNewImage":"\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14\u0e20\u0e32\u0e1e",//no i18n
"crm.fileuploader.removefile":"\u0e19\u0e33\u0e2d\u0e2d\u0e01",//no i18n
	"voc.gc.configure":"\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e04\u0e48\u0e32",//no i18n
"Edit":"\u0e41\u0e01\u0e49\u0e44\u0e02",//no i18n
	"crm.criteria.secondaryfield.valid.check" : "\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19\u0e04\u0e48\u0e32\u0e17\u0e35\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e19\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a {0}",
	"crm.label.field":"\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25",//no i18n
"crm.label.value":"\u0e04\u0e48\u0e32",//no i18n
	"crm.button.clone":"\u0e25\u0e2d\u0e01\u0e41\u0e1a\u0e1a",//no i18n
	"sentiment.criteria.wrongcriteria":"\u0e04\u0e48\u0e32\u0e40\u0e01\u0e13\u0e11\u0e4c\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {0}",//no i18n
"crm.mb.field.common.splc":"\u0e44\u0e21\u0e48\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30\u0e1e\u0e34\u0e40\u0e28\u0e29 \u0e1b\u0e49\u0e2d\u0e19\u0e04\u0e48\u0e32\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07",//no i18n
	"crm.view.attachment.download":"\u0e14\u0e32\u0e27\u0e19\u0e4c\u0e42\u0e2b\u0e25\u0e14",//no i18n
"crm.label.view":"\u0e14\u0e39",//no i18n
	"crm.label.field.plural":"\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25",//no i18n
	"crm.label.in.minutes":"{0} (\u0e2b\u0e19\u0e48\u0e27\u0e22\u0e19\u0e32\u0e17\u0e35)",//no i18n
	"crm.security.roles.list":"\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e1a\u0e17\u0e1a\u0e32\u0e17",//no i18n
"crm.security.roles.lookup.info":"\u0e42\u0e1b\u0e23\u0e14\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e1a\u0e17\u0e1a\u0e32\u0e17\u0e08\u0e32\u0e01\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",//no i18n
"crm.territory.addterritory":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e02\u0e15\u0e41\u0e14\u0e19",//no i18n
"crm.title.edit.territory":"\u0e41\u0e01\u0e49\u0e44\u0e02\u0e40\u0e02\u0e15\u0e41\u0e14\u0e19",//no i18n
"crm.territory.title.assign.territories":"\u0e21\u0e2d\u0e1a\u0e2b\u0e21\u0e32\u0e22\u0e40\u0e02\u0e15\u0e41\u0e14\u0e19",//no i18n
	"crm.label.context.help":"\u0e27\u0e34\u0e18\u0e35\u0e43\u0e0a\u0e49",//no i18n
"crm.label.from":"\u0e08\u0e32\u0e01",//no i18n
"crm.label.to":"\u0e16\u0e36\u0e07",//no i18n
"workflow.option.webhookFailure.fromDate":"\u0e08\u0e32\u0e01\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48",//no i18n
"workflow.option.webhookFailure.toDate":"\u0e16\u0e36\u0e07\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48",//no i18n
"crm.custom.field.less.than.equalto":"{0} \u0e08\u0e30\u0e15\u0e49\u0e2d\u0e07\u0e19\u0e49\u0e2d\u0e22\u0e01\u0e27\u0e48\u0e32\u0e2b\u0e23\u0e37\u0e2d\u0e40\u0e17\u0e48\u0e32\u0e01\u0e31\u0e1a {1}",//no i18n
"crm.template.listview.search.no.results":"\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e1c\u0e25\u0e25\u0e31\u0e1e\u0e18\u0e4c",//no i18n
"crm.wf.usage.date.criteria.error.msg":"\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48 \u201d\u0e08\u0e32\u0e01\u201c \u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e1b\u0e47\u0e19\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e2b\u0e25\u0e31\u0e07\u0e08\u0e32\u0e01\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48 \u201d\u0e16\u0e36\u0e07\u201c",//no i18n
"crm.label.tag.new":"\u0e41\u0e17\u0e47\u0e01\u0e43\u0e2b\u0e21\u0e48",//no i18n
"crm.label.enter.tag":"\u0e1b\u0e49\u0e2d\u0e19\u0e41\u0e17\u0e47\u0e01",//no i18n
"Administrator":"\u0e1c\u0e39\u0e49\u0e14\u0e39\u0e41\u0e25\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"Standard":"\u0e21\u0e32\u0e15\u0e23\u0e10\u0e32\u0e19",//no i18n
"crux.comboBox.max.limit":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {0} {1} \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23.",//no i18n
"crm.button.add":"\u0e40\u0e1e\u0e34\u0e48\u0e21",//no i18n
"crm.label.users":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"crm.workflow.alert.roles":"\u0e1a\u0e17\u0e1a\u0e32\u0e17",//no i18n
"crm.security.groups":"\u0e01\u0e25\u0e38\u0e48\u0e21",//no i18n
"crm.label.available":"\u0e1e\u0e23\u0e49\u0e2d\u0e21\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19",//no i18n
"crm.label.assign.manually":"\u0e21\u0e2d\u0e1a\u0e2b\u0e21\u0e32\u0e22",//no i18n
"crm.globalsearch.option.all":"\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//no i18n
"webform.status.Active":"\u0e40\u0e1b\u0e34\u0e14\u0e17\u0e33\u0e07\u0e32\u0e19\u0e2d\u0e22\u0e39\u0e48",//no i18n
"Inactive":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e40\u0e1b\u0e34\u0e14\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19",//no i18n
"Confirmed":"\u0e22\u0e37\u0e19\u0e22\u0e31\u0e19\u0e41\u0e25\u0e49\u0e27",//no i18n
"DeletedUser":"\u0e25\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.user.component.unconfirmed":"\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e22\u0e37\u0e19\u0e22\u0e31\u0e19",//no i18n
"crm.feed.group.admin":"\u0e1c\u0e39\u0e49\u0e14\u0e39\u0e41\u0e25\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"crm.ln.lable.current":"\u0e1b\u0e31\u0e08\u0e08\u0e38\u0e1a\u0e31\u0e19",//no i18n
"crm.security.group.users.empty":"\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"crm.label.picklist.none":"\u0e44\u0e21\u0e48\u0e21\u0e35",//no i18n
"crm.label.selected":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.notSelected":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e40\u0e25\u0e37\u0e2d\u0e01",//no i18n
"AM":"AM",//no i18n
"Call":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23",//no i18n
"crm.phoneNo.Link.Title":"\u0e42\u0e17\u0e23\u0e1c\u0e48\u0e32\u0e19 Skype",//no i18n
"crm.button.cancel":"\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01",//no i18n
"crm.button.save":"\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01",//no i18n
"crm.no.data.found":"\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25",//no i18n
"crm.usrpop.non.selected":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e17\u0e35\u0e48\u0e40\u0e25\u0e37\u0e2d\u0e01",//no i18n
"crm.zti.label.user":"\u0e0a\u0e37\u0e48\u0e2d\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"crm.auditlog.user":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"cob.role":"\u0e1a\u0e17\u0e1a\u0e32\u0e17",//no i18n
"zoho.email":"\u0e2d\u0e35\u0e40\u0e21\u0e25",//no i18n
"Profile":"\u0e42\u0e1b\u0e23\u0e44\u0e1f\u0e25\u0e4c",//no i18n
"crm.label.no.options.found":"\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01",//no i18n
"crm.globalsearch.search.title":"\u0e04\u0e49\u0e19\u0e2b\u0e32",//no i18n
"None":"\u0e44\u0e21\u0e48\u0e21\u0e35",//no i18n
"crm.label.criteria.pattern":"\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e40\u0e01\u0e13\u0e11\u0e4c",//no i18n
"crm.label.edit.criteria.pattern":"\u0e41\u0e01\u0e49\u0e44\u0e02\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a",//no i18n
"criteria.error.alert.brackets.count.mismatch":"\u0e27\u0e07\u0e40\u0e25\u0e47\u0e1a\u0e40\u0e2b\u0e25\u0e35\u0e48\u0e22\u0e21\u0e02\u0e2d\u0e07\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e44\u0e21\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e19",//no i18n
"criteria.error.alert.brackets.invalid":"\u0e21\u0e35\u0e27\u0e07\u0e40\u0e25\u0e47\u0e1a\u0e40\u0e2b\u0e25\u0e35\u0e48\u0e22\u0e21\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e25\u0e49\u0e2d\u0e21\u0e23\u0e2d\u0e1a\u0e15\u0e31\u0e27\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e40\u0e07\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e02",//no i18n
"crm.criteria.number.notmatch.check":"\u0e42\u0e1b\u0e23\u0e14\u0e15\u0e23\u0e27\u0e08\u0e2a\u0e2d\u0e1a\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e17\u0e35\u0e48 {0}",//no i18n
"criteria.error.alert.other.params":"\u0e40\u0e19\u0e37\u0e49\u0e2d\u0e2b\u0e32\u0e43\u0e19\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07",//no i18n
"crm.label.search.for.users":"\u0e04\u0e49\u0e19\u0e2b\u0e32\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"criteria.error.alert.andor.rowcount.mismatch":"\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e40\u0e01\u0e13\u0e11\u0e4c\u0e44\u0e21\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e1a\u0e40\u0e07\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e02\u0e17\u0e35\u0e48\u0e04\u0e38\u0e13\u0e40\u0e25\u0e37\u0e2d\u0e01",//no i18n
"criteria.error.alert.critnum.rowcount.mismatch":"\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e40\u0e01\u0e13\u0e11\u0e4c\u0e44\u0e21\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e1a\u0e40\u0e07\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e02\u0e17\u0e35\u0e48\u0e04\u0e38\u0e13\u0e40\u0e25\u0e37\u0e2d\u0e01",//no i18n
"and":"\u0e41\u0e25\u0e30",//no i18n
"or":"\u0e2b\u0e23\u0e37\u0e2d",//no i18n
"crm.label.or":"\u0e2b\u0e23\u0e37\u0e2d",//no i18n
"crm.label.and":"\u0e41\u0e25\u0e30",//no i18n
"crm.criteria.fieldlabel.valid.check":"\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19\u0e1b\u0e49\u0e32\u0e22\u0e0a\u0e37\u0e48\u0e2d\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e43\u0e19\u0e41\u0e16\u0e27 {0}",//no i18n
"crm.criteria.condition.valid.check":"\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19\u0e40\u0e07\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e02\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a {0}",//no i18n
"crm.field.valid.check":"\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19 {0} \u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07",//no i18n
"crm.custom.field.less.than.to":"\u0e0a\u0e48\u0e27\u0e07 <i>\u0e08\u0e32\u0e01</i> \u0e15\u0e49\u0e2d\u0e07\u0e44\u0e21\u0e48\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32\u0e0a\u0e48\u0e27\u0e07 <i>\u0e16\u0e36\u0e07</i>",//no i18n
"crm.alert.label.savepattern":"\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e01\u0e48\u0e2d\u0e19\u0e40\u0e1b\u0e25\u0e35\u0e48\u0e22\u0e19\u0e41\u0e1b\u0e25\u0e07\u0e40\u0e01\u0e13\u0e11\u0e4c",//no i18n
"crm.criteria.max.rowcnt.exceeds":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e01\u0e13\u0e11\u0e4c\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21",//no i18n
"is":"\u0e40\u0e1b\u0e47\u0e19",//no i18n
"isn\'t":"\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48",//no i18n
"contains":"\u0e21\u0e35",//no i18n
"doesn\'t\ contain":"\u0e44\u0e21\u0e48\u0e21\u0e35",//no i18n
"starts with":"\u0e40\u0e23\u0e34\u0e48\u0e21\u0e14\u0e49\u0e27\u0e22",//no i18n
"ends with":"\u0e2a\u0e34\u0e49\u0e19\u0e2a\u0e38\u0e14\u0e14\u0e49\u0e27\u0e22",//no i18n
"is empty":"\u0e27\u0e48\u0e32\u0e07\u0e40\u0e1b\u0e25\u0e48\u0e32",//no i18n
"is not empty":"\u0e44\u0e21\u0e48\u0e27\u0e48\u0e32\u0e07\u0e40\u0e1b\u0e25\u0e48\u0e32",//no i18n
"is before":"\u0e01\u0e48\u0e2d\u0e19",//no i18n
"is after":"\u0e2b\u0e25\u0e31\u0e07",//no i18n
"between":"\u0e23\u0e30\u0e2b\u0e27\u0e48\u0e32\u0e07",//no i18n
"not between":"\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48\u0e23\u0e30\u0e2b\u0e27\u0e48\u0e32\u0e07",//no i18n
"Today":"\u0e27\u0e31\u0e19\u0e19\u0e35\u0e49",//no i18n
"Tommorow":"\u0e27\u0e31\u0e19\u0e1e\u0e23\u0e38\u0e48\u0e07\u0e19\u0e35\u0e49",//no i18n
"Tommorow Onwards":"\u0e01\u0e32\u0e23\u0e40\u0e23\u0e34\u0e48\u0e21\u0e27\u0e31\u0e19\u0e1e\u0e23\u0e38\u0e48\u0e07\u0e19\u0e35\u0e49",//no i18n
"Yesterday":"\u0e40\u0e21\u0e37\u0e48\u0e2d\u0e27\u0e32\u0e19",//no i18n
"Till Yesterday":"\u0e08\u0e19\u0e16\u0e36\u0e07\u0e40\u0e21\u0e37\u0e48\u0e2d\u0e27\u0e32\u0e19",//no i18n
"Last Month":"\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"Current Month":"\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e1b\u0e31\u0e08\u0e08\u0e38\u0e1a\u0e31\u0e19",//no i18n
"Next Month":"\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
"Last Week":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"Current Week":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e1b\u0e31\u0e08\u0e08\u0e38\u0e1a\u0e31\u0e19",//no i18n
"Next Week":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
"Age in Days":"\u0e2d\u0e32\u0e22\u0e38\u0e43\u0e19\u0e41\u0e1a\u0e1a\u0e27\u0e31\u0e19",//no i18n
"Due in Days":"\u0e16\u0e36\u0e07\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e43\u0e19\u0e41\u0e1a\u0e1a\u0e27\u0e31\u0e19",//no i18n

"Scheduled":"\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e01\u0e32\u0e23\u0e41\u0e25\u0e49\u0e27",//no i18n
"Attended Dialled":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23\u0e2d\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e21\u0e35\u0e04\u0e19\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22",//no i18n
"Unattended Dialled":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23\u0e2d\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e21\u0e35\u0e04\u0e19\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22",//no i18n
"Overdue":"\u0e40\u0e25\u0e22\u0e01\u0e33\u0e2b\u0e19\u0e14",//no i18n
"Cancelled":"\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01\u0e41\u0e25\u0e49\u0e27",//no i18n
"Received":"\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"Missed":"\u0e1e\u0e25\u0e32\u0e14\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22",//no i18n
"crm.alert.character.not.allowed":"{0} \u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15",//no i18n
"crm.condition.in.last":"\u0e43\u0e19\u0e04\u0e23\u0e31\u0e49\u0e07\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.zinvoice.dueIn":"\u0e04\u0e23\u0e1a\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e43\u0e19",//no i18n
"on":"\u0e40\u0e21\u0e37\u0e48\u0e2d",//no i18n
"before":"\u0e01\u0e48\u0e2d\u0e19",//no i18n
"crm.label.general.small.after":"\u0e2b\u0e25\u0e31\u0e07",//no i18n
"crm.thisweek":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e19\u0e35\u0e49",//no i18n
"crm.label.this.month":"\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e19\u0e35\u0e49",//no i18n
"crm.thisyear":"\u0e1b\u0e35\u0e19\u0e35\u0e49",//no i18n
"crm.source.user.and.system":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e41\u0e25\u0e30\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"crm.source.user.or.system":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e2b\u0e23\u0e37\u0e2d\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"crm.label.system2":"\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"crm.source.user.only":"\u0e40\u0e09\u0e1e\u0e32\u0e30\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.source.system.only":"\u0e42\u0e14\u0e22\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.condition.till.today":"\u0e08\u0e19\u0e16\u0e36\u0e07\u0e27\u0e31\u0e19\u0e19\u0e35\u0e49",//no i18n
"game.month.left":"1 \u0e40\u0e14\u0e37\u0e2d\u0e19",//no i18n
"game.months.left":"{0} \u0e40\u0e14\u0e37\u0e2d\u0e19",//no i18n
"crm.condition.last.30.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 30 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.condition.last.60.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 60 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.condition.last.90.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 90 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.filter.typehere":"\u0e1e\u0e34\u0e21\u0e1e\u0e4c\u0e17\u0e35\u0e48\u0e19\u0e35\u0e48",//no i18n
"crm.filter.is.not":"\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48",//no i18n
"crm.condition.until.now":"\u0e08\u0e19\u0e16\u0e36\u0e07\u0e15\u0e2d\u0e19\u0e19\u0e35\u0e49",//no i18n
"crm.filter.email.isblocked":"\u0e16\u0e39\u0e01\u0e1a\u0e25\u0e47\u0e2d\u0e01",//no i18n
"crm.filter.email.isnotblocked":"\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e1a\u0e25\u0e47\u0e2d\u0e01",//no i18n
"crm.label.no.results.match":"\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e1c\u0e25\u0e17\u0e35\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e19",//no i18n
"crm.label.select.user":"\u0e04\u0e25\u0e34\u0e01\u0e40\u0e1e\u0e37\u0e48\u0e2d\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"current.logged.in.user":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e17\u0e35\u0e48\u0e40\u0e02\u0e49\u0e32\u0e2a\u0e39\u0e48\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"current.logged.in.user.definition":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e17\u0e35\u0e48\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e02\u0e2d\u0e07\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19",//no i18n
"crm.security.group":"\u0e01\u0e25\u0e38\u0e48\u0e21",//no i18n
"crm.security.role":"\u0e1a\u0e17\u0e1a\u0e32\u0e17",//no i18n
"Date":"\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48",//no i18n
"crm.field.valid.decimal.check2":"\u0e15\u0e33\u0e41\u0e2b\u0e19\u0e48\u0e07\u0e17\u0e28\u0e19\u0e34\u0e22\u0e21\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25 <i>{0}</i> \u0e08\u0e30\u0e15\u0e49\u0e2d\u0e07\u0e19\u0e49\u0e2d\u0e22\u0e01\u0e27\u0e48\u0e32\u0e2b\u0e23\u0e37\u0e2d\u0e40\u0e17\u0e48\u0e32\u0e01\u0e31\u0e1a {1}",//no i18n
"crm.field.empty.check":"{0} \u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e27\u0e48\u0e32\u0e07\u0e40\u0e1b\u0e25\u0e48\u0e32",//no i18n

//filter related keys-start
"crm.lead.prediction.convert.high":"\u0e2a\u0e39\u0e07",//no i18n
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.convert.medium":"\u0e1b\u0e32\u0e19\u0e01\u0e25\u0e32\u0e07",//no i18n
"crm.lead.prediction.convert.low":"\u0e15\u0e48\u0e33",//no i18n
"crm.lead.prediction.tooltip.convert":"\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49",//no i18n
"crm.lead.prediction.tooltip.score":"\u0e04\u0e30\u0e41\u0e19\u0e19",//no i18n
"Planned":"\u0e27\u0e32\u0e07\u0e41\u0e1c\u0e19\u0e41\u0e25\u0e49\u0e27",//no i18n
"Invited":"\u0e44\u0e14\u0e49\u0e40\u0e0a\u0e34\u0e0d",//no i18n
"Sent":"\u0e2a\u0e48\u0e07\u0e41\u0e25\u0e49\u0e27",//no i18n
"Received":"\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"Opened":"\u0e40\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27",//no i18n
"Responded":"\u0e15\u0e2d\u0e1a\u0e2a\u0e19\u0e2d\u0e07\u0e41\u0e25\u0e49\u0e27",//no i18n
"Bounced":"\u0e15\u0e35\u0e01\u0e25\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"Opted\ Out":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e21\u0e48\u0e23\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.with.open":"\u0e21\u0e35 {0} \u0e17\u0e35\u0e48\u0e40\u0e1b\u0e34\u0e14\u0e2d\u0e22\u0e39\u0e48",//no i18n
"crm.filter.label.without.open":"\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e21\u0e35 {0} \u0e17\u0e35\u0e48\u0e40\u0e1b\u0e34\u0e14\u0e2d\u0e22\u0e39\u0e48",//no i18n
"crm.filter.label.without.any":"\u0e42\u0e14\u0e22\u0e44\u0e21\u0e48\u0e21\u0e35 {0}",//no i18n
"crm.filter.label.with.module":"\u0e21\u0e35 {0}",//no i18n
"Overdue":"\u0e40\u0e25\u0e22\u0e01\u0e33\u0e2b\u0e19\u0e14",//no i18n
"crm.filter.label.activity.due":"{0} \u0e04\u0e23\u0e1a\u0e01\u0e33\u0e2b\u0e19\u0e14",//no i18n
"crm.filter.label.activity.done":"{0} \u0e17\u0e33\u0e41\u0e25\u0e49\u0e27",//no i18n
"Notes":"\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d",//no i18n
"crm.filter.label.notes.added":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.filter.email.status":"\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.label.filter.email.clicked":"\u0e04\u0e25\u0e34\u0e01\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.filter.email.responded":"\u0e17\u0e35\u0e48\u0e15\u0e2d\u0e1a\u0e2a\u0e19\u0e2d\u0e07", //no i18n
"crm.label.filter.email.info":"\u0e01\u0e23\u0e2d\u0e07\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e2d\u0e34\u0e07\u0e15\u0e32\u0e21\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14\u0e02\u0e2d\u0e07\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e17\u0e35\u0e48\u0e2a\u0e48\u0e07/\u0e23\u0e31\u0e1a\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13",//no i18n
"crm.filter.label.sent":"\u0e2a\u0e48\u0e07\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.not.sent":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e2a\u0e48\u0e07",//no i18n
"crm.filter.label.opened":"\u0e40\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.not.opened":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e40\u0e1b\u0e34\u0e14",//no i18n
"crm.filter.label.received":"\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.not.received":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a",//no i18n
"crm.filter.label.bounced":"\u0e15\u0e35\u0e01\u0e25\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.opened.not.replied":"\u0e17\u0e35\u0e48\u0e40\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27\u0e41\u0e25\u0e30\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e15\u0e2d\u0e1a\u0e01\u0e25\u0e31\u0e1a", //no i18n
"crm.filter.label.any":"\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e43\u0e14\u0e02\u0e49\u0e32\u0e07\u0e15\u0e49\u0e19",//no i18n
"crm.zia.config.potential.amount":"\u0e08\u0e33\u0e19\u0e27\u0e19\u0e40\u0e07\u0e34\u0e19 {0}",//no i18n
"Quote\ Stage":"\u0e02\u0e31\u0e49\u0e19\u0e15\u0e2d\u0e19\u0e17\u0e35\u0e48 {0}",//no i18n
"crm.module.owner":"\u0e40\u0e08\u0e49\u0e32\u0e02\u0e2d\u0e07 {0}",//no i18n
"Potential\ Closing\ Date":"\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e1b\u0e34\u0e14 {0}",//no i18n
"crm.lead.prediction.likely.convert":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07",//no i18n
"crm.predictions.feature.label":"\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32",//no i18n
"crm.intelligence.prediction.likelywin":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e18\u0e38\u0e23\u0e01\u0e34\u0e08",//no i18n
"crm.intelligence.prediction.likelylose":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e2a\u0e39\u0e0d\u0e40\u0e2a\u0e35\u0e22",//no i18n
"crm.intelligence.prediction.halfchance":"\u0e42\u0e2d\u0e01\u0e32\u0e2a 50:50",//no i18n
"crm.intelligence.prediction.score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32",//no i18n
"crm.lead.prediction.recent.score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.intelligence.prediction.lastconv":"\u0e01\u0e32\u0e23\u0e41\u0e1b\u0e25\u0e07 3 \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.intelligence.prediction.recordsfocus":"\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e08\u0e30\u0e21\u0e38\u0e48\u0e07\u0e40\u0e19\u0e49\u0e19",//no i18n
"crm.intelligence.prediction.slowmoving":"\u0e01\u0e32\u0e23\u0e40\u0e04\u0e25\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e2b\u0e27\u0e0a\u0e49\u0e32",//no i18n
"crm.intelligence.prediction.trend.down":"\u0e41\u0e19\u0e27\u0e42\u0e19\u0e49\u0e21\u0e25\u0e14\u0e25\u0e07\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.label.touched.records":"\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.untouched.records":"\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d",//no i18n
"crm.label.record.action":"\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e01\u0e31\u0e1a\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19",//no i18n
"workflow.rule.view.label.Modified":"\u0e41\u0e01\u0e49\u0e44\u0e02\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.not.modified":"\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e41\u0e01\u0e49\u0e44\u0e02",//no i18n
"crm.label.related.records.action":"\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e01\u0e31\u0e1a\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e02\u0e49\u0e2d\u0e07",//no i18n
"Done":"\u0e40\u0e2a\u0e23\u0e47\u0e08\u0e2a\u0e34\u0e49\u0e19",//no i18n
"crm.label.not.done":"\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e17\u0e33",//no i18n
"sentiment.model":"\u0e04\u0e27\u0e32\u0e21\u0e04\u0e34\u0e14\u0e40\u0e2b\u0e47\u0e19\u0e02\u0e2d\u0e07\u0e2d\u0e35\u0e40\u0e21\u0e25",//no i18n
"sentiment.criteria.count":"\u0e08\u0e33\u0e19\u0e27\u0e19",//no i18n
"sentiment.criteria.percentage":"\u0e23\u0e49\u0e2d\u0e22\u0e25\u0e30",//no i18n
"sentiment.criteria.lastmail":"\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"Chats":"\u0e2a\u0e19\u0e17\u0e19\u0e32",//no i18n
"Attended":"\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.lead.prediction.popup.text":"\u0e40\u0e21\u0e37\u0e48\u0e2d\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07\u0e2d\u0e22\u0e39\u0e48\u0e17\u0e35\u0e48 {0} \u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32\u0e04\u0e27\u0e23\u0e2d\u0e22\u0e39\u0e48\u0e23\u0e30\u0e2b\u0e27\u0e48\u0e32\u0e07 {1}",//no i18n
"crm.lead.prediction.popup.final":"\u0e42\u0e1b\u0e23\u0e14\u0e40\u0e1b\u0e25\u0e35\u0e48\u0e22\u0e19\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07\u0e43\u0e2b\u0e49\u0e2a\u0e2d\u0e14\u0e04\u0e25\u0e49\u0e2d\u0e07 \u0e41\u0e25\u0e30\u0e25\u0e2d\u0e07\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07",//no i18n
"crm.custom.field.less.than.to1":"\u0e0a\u0e48\u0e27\u0e07 \u0e08\u0e32\u0e01 \u0e15\u0e49\u0e2d\u0e07\u0e44\u0e21\u0e48\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32\u0e0a\u0e48\u0e27\u0e07 \u0e16\u0e36\u0e07",//no i18n
"Last\ Activity\ Date":"\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.label.vendor.name":"\u0e0a\u0e37\u0e48\u0e2d {0}",//no i18n
"hours":"\u0e0a\u0e31\u0e48\u0e27\u0e42\u0e21\u0e07",//no i18n
"days":"\u0e27\u0e31\u0e19",//no i18n
"weeks":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c",//no i18n
"months":"\u0e40\u0e14\u0e37\u0e2d\u0e19",//no i18n
"years":"\u0e1b\u0e35",//no i18n
"crm.label.general.small.after":"\u0e2b\u0e25\u0e31\u0e07",//no i18n
"Last\ Week":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"Last\ Month":"\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.module.name":"\u0e0a\u0e37\u0e48\u0e2d {0}",//no i18n
"Campaign":"\u0e41\u0e04\u0e21\u0e40\u0e1b\u0e0d",//no i18n
"Tasks":"\u0e07\u0e32\u0e19",//no i18n
"Calls":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23",//no i18n
"Events":"\u0e40\u0e2b\u0e15\u0e38\u0e01\u0e32\u0e23\u0e13\u0e4c",//no i18n
"sentiment.criteria.wrongcriteria":"\u0e04\u0e48\u0e32\u0e40\u0e01\u0e13\u0e11\u0e4c\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {0}",//no i18n
"crm.chosen.minimum.input.text":"\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e19\u0e49\u0e2d\u0e22 {0} \u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30",//no i18n
"crm.intelligence.prediction.trendup":"\u0e41\u0e19\u0e27\u0e42\u0e19\u0e49\u0e21\u0e2a\u0e39\u0e07\u0e02\u0e36\u0e49\u0e19",//no i18n
"crm.intelligence.prediction.trenddown":"\u0e41\u0e19\u0e27\u0e42\u0e19\u0e49\u0e21\u0e25\u0e14\u0e25\u0e07",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"\u0e40\u0e2a\u0e23\u0e47\u0e08\u0e2a\u0e21\u0e1a\u0e39\u0e23\u0e13\u0e4c" ,//no i18n
"crm.label.success":"\u0e2a\u0e33\u0e40\u0e23\u0e47\u0e08" ,//no i18n
"crm.label.Failure":"\u0e25\u0e49\u0e21\u0e40\u0e2b\u0e25\u0e27" ,//no i18n
"Both":"\u0e17\u0e31\u0e49\u0e07\u0e2a\u0e2d\u0e07" ,//no i18n
"crm.condition.cannot.empty":"\u0e40\u0e07\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e02\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e27\u0e48\u0e32\u0e07\u0e40\u0e1b\u0e25\u0e48\u0e32",//no i18n
"crm.condition.last.30.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 30 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.condition.last.60.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 60 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.condition.last.90.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 90 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.sentiment.Positive":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01",//no i18n
"crm.sentiment.Negative":"\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//no i18n
"sentiment.positiveandnegative":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01\u0e41\u0e25\u0e30\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//no i18n
"sentiment.positiveornegative":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01\u0e2b\u0e23\u0e37\u0e2d\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//no i18n
"sentiment.positiveonly":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"sentiment.negativeonly":"\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.sentiment.Neutral":"\u0e40\u0e09\u0e22\u0e46",//no i18n
"crm.filters.select.campaign.type":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e1b\u0e23\u0e30\u0e40\u0e20\u0e17 {0}",//no i18n
"crm.filters.select.campaign.status":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e2a\u0e16\u0e32\u0e19\u0e30 {0}",//no i18n
"campaign.Member" : "\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01",//no i18n
	"Service":"\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",//no i18n
"Activities":"\u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21",//no i18n
"crm.livedesk.pot.nextdays":"{0} \u0e27\u0e31\u0e19\u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
"Today\ +\ Overdue":"\u0e27\u0e31\u0e19\u0e19\u0e35\u0e49 + \u0e17\u0e35\u0e48\u0e40\u0e25\u0e22\u0e01\u0e33\u0e2b\u0e19\u0e14",//no i18n
"crm.source.user.and.system":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e41\u0e25\u0e30\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"crm.source.user.or.system":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e2b\u0e23\u0e37\u0e2d\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"User":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"crm.source.user.only":"\u0e40\u0e09\u0e1e\u0e32\u0e30\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.source.system.only":"\u0e42\u0e14\u0e22\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"Scheduled":"\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e01\u0e32\u0e23\u0e41\u0e25\u0e49\u0e27",//no i18n
"Attended\ Dialled":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23\u0e2d\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e21\u0e35\u0e04\u0e19\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22",//no i18n
"Unattended\ Dialled":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23\u0e2d\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e21\u0e35\u0e04\u0e19\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22",//no i18n
"Cancelled":"\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.email.isblocked":"\u0e16\u0e39\u0e01\u0e1a\u0e25\u0e47\u0e2d\u0e01",//no i18n
"crm.filter.email.isnotblocked":"\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e1a\u0e25\u0e47\u0e2d\u0e01",//no i18n
"condition.till.now":"\u0e08\u0e19\u0e16\u0e36\u0e07\u0e15\u0e2d\u0e19\u0e19\u0e35\u0e49",//no i18n
"crm.recurring.no.months":"{0} \u0e40\u0e14\u0e37\u0e2d\u0e19",//no i18n
"crm.lead.prediction.tooltip":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07 - \u0e0a\u0e48\u0e27\u0e07\u0e04\u0e30\u0e41\u0e19\u0e19",//no i18n
"crm.website.activity":"\u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21\u0e40\u0e27\u0e47\u0e1a\u0e44\u0e0b\u0e15\u0e4c",//no i18n
"crm.label.More":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21",//no i18n
"crm.label.By":"\u0e42\u0e14\u0e22",//no i18n
"crm.chosen.searching.text":"\u0e01\u0e33\u0e25\u0e31\u0e07\u0e04\u0e49\u0e19\u0e2b\u0e32...",//no i18n
"crm.label.memberstatus.is":"\u0e41\u0e25\u0e30\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01\u0e04\u0e37\u0e2d",//no i18n
"crm.events.duration":"\u0e23\u0e30\u0e22\u0e30\u0e40\u0e27\u0e25\u0e32",//no i18n
"crm.title.clear.name":"\u0e25\u0e49\u0e32\u0e07",//no i18n
"crm.label.status.is":"\u0e41\u0e25\u0e30\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e04\u0e37\u0e2d",//no i18n
"zia.last3.help":"\u0e01\u0e32\u0e23\u0e2a\u0e19\u0e17\u0e19\u0e32\u0e23\u0e27\u0e21\u0e16\u0e36\u0e07\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23 \u0e07\u0e32\u0e19, {0}, \u0e2d\u0e35\u0e40\u0e21\u0e25\u0e17\u0e35\u0e48\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a \u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d \u0e01\u0e32\u0e23\u0e40\u0e22\u0e35\u0e48\u0e22\u0e21\u0e0a\u0e21\u0e44\u0e0b\u0e15\u0e4c \u0e02\u0e49\u0e2d\u0e04\u0e34\u0e14\u0e40\u0e2b\u0e47\u0e19\u0e1a\u0e19\u0e2a\u0e37\u0e48\u0e2d\u0e2a\u0e31\u0e07\u0e04\u0e21 \u0e04\u0e33\u0e02\u0e2d\u0e01\u0e32\u0e23\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e08\u0e32\u0e01 Desk",//no i18n
"crm.label.tag.related.to":"\u0e17\u0e35\u0e48\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e02\u0e49\u0e2d\u0e07\u0e01\u0e31\u0e1a",//no i18n
"crm.alert.maximum.text.values.contains":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e1b\u0e49\u0e2d\u0e19\u0e04\u0e48\u0e32\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {0} \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e43\u0e19\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e19\u0e35\u0e49",//no i18n
//filter related keys-end

//smart filter keys
"crm.lead.prediction.convert.high":"\u0e2a\u0e39\u0e07",//no i18n
"crm.lead.prediction.convert.medium":"\u0e1b\u0e32\u0e19\u0e01\u0e25\u0e32\u0e07",//no i18n
"crm.lead.prediction.convert.low":"\u0e15\u0e48\u0e33",//no i18n
"crm.lead.prediction.focus.nofollowup":"\u0e44\u0e21\u0e48\u0e21\u0e35\u0e01\u0e32\u0e23\u0e15\u0e34\u0e14\u0e15\u0e32\u0e21\u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
"crm.lead.prediction.likely.convert":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07",//no i18n
"crm.lead.prediction.recent.score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.lead.prediction.tooltip":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07 - \u0e0a\u0e48\u0e27\u0e07\u0e04\u0e30\u0e41\u0e19\u0e19",//no i18n
"crm.lead.prediction.tooltip.convert":"\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49",//no i18n
"crm.lead.prediction.tooltip.score":"\u0e04\u0e30\u0e41\u0e19\u0e19",//no i18n
"Planned":"\u0e27\u0e32\u0e07\u0e41\u0e1c\u0e19\u0e41\u0e25\u0e49\u0e27",//no i18n
"Planned":"\u0e27\u0e32\u0e07\u0e41\u0e1c\u0e19\u0e41\u0e25\u0e49\u0e27",//no i18n
"Invited":"\u0e44\u0e14\u0e49\u0e40\u0e0a\u0e34\u0e0d",//no i18n
"Sent":"\u0e2a\u0e48\u0e07\u0e41\u0e25\u0e49\u0e27",//no i18n
"Failed":"\u0e25\u0e49\u0e21\u0e40\u0e2b\u0e25\u0e27",//no i18n
"Opened":"\u0e40\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27",//no i18n
"Bounced":"\u0e15\u0e35\u0e01\u0e25\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"Responded":"\u0e15\u0e2d\u0e1a\u0e2a\u0e19\u0e2d\u0e07\u0e41\u0e25\u0e49\u0e27",//no i18n
"Opted\ Out":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e21\u0e48\u0e23\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"Clicked":"\u0e04\u0e25\u0e34\u0e01\u0e41\u0e25\u0e49\u0e27",//no i18n
"Marked.as.spam":"\u0e17\u0e33\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e2b\u0e21\u0e32\u0e22\u0e27\u0e48\u0e32\u0e40\u0e1b\u0e47\u0e19\u0e2a\u0e41\u0e1b\u0e21",//no i18n
"Member\ Status":"\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01",//no i18n
"Camp\ Member\ Status":"\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01\u0e41\u0e04\u0e21\u0e40\u0e1b\u0e0d",//no i18n
"Campaign\ Member":"\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01\u0e41\u0e04\u0e21\u0e40\u0e1b\u0e0d",//no i18n
"crm.zinvoice.dueIn":"\u0e04\u0e23\u0e1a\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e43\u0e19",//no i18n
"crm.zinvoice.dueby":"\u0e04\u0e23\u0e1a\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e20\u0e32\u0e22\u0e43\u0e19",//no i18n
"Rollbacked":"\u0e22\u0e49\u0e2d\u0e19\u0e01\u0e25\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.with.open":"\u0e21\u0e35 {0} \u0e17\u0e35\u0e48\u0e40\u0e1b\u0e34\u0e14\u0e2d\u0e22\u0e39\u0e48",//no i18n
"crm.filter.label.with.module":"\u0e21\u0e35 {0}",//no i18n
"crm.filter.label.activity.due":"{0} \u0e04\u0e23\u0e1a\u0e01\u0e33\u0e2b\u0e19\u0e14",//no i18n
"crm.filter.label.activity.done":"{0} \u0e17\u0e33\u0e41\u0e25\u0e49\u0e27",//no i18n
"Date":"\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48",//no i18n
"crm.filter.label.notes.added":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.filter.email.status":"\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2d\u0e35\u0e40\u0e21\u0e25",//no i18n
"crm.filter.label.sent":"\u0e2a\u0e48\u0e07\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.not.sent":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e2a\u0e48\u0e07",//no i18n
"crm.filter.label.opened":"\u0e40\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.not.opened":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e40\u0e1b\u0e34\u0e14",//no i18n
"crm.filter.label.received":"\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.not.received":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a",//no i18n
"crm.filter.label.bounced":"\u0e15\u0e35\u0e01\u0e25\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.opened.not.replied":"\u0e40\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27\u0e41\u0e25\u0e30\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e15\u0e2d\u0e1a\u0e01\u0e25\u0e31\u0e1a",//no i18n
"crm.filter.label.any":"\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e43\u0e14\u0e02\u0e49\u0e32\u0e07\u0e15\u0e49\u0e19",//no i18n
"crm.zia.config.potential.amount":"\u0e08\u0e33\u0e19\u0e27\u0e19\u0e40\u0e07\u0e34\u0e19 {0}",//no i18n
"Quote\ Stage":"\u0e02\u0e31\u0e49\u0e19\u0e15\u0e2d\u0e19\u0e17\u0e35\u0e48 {0}",//no i18n
"crm.module.owner":"\u0e40\u0e08\u0e49\u0e32\u0e02\u0e2d\u0e07 {0}",//no i18n
"crm.label.Potential\ Closing\ Date":"\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e1b\u0e34\u0e14\u0e1c\u0e39\u0e49\u0e17\u0e35\u0e48\u0e21\u0e35\u0e28\u0e31\u0e01\u0e22\u0e20\u0e32\u0e1e",//no i18n
"crm.lead.prediction.likely.convert":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07",//no i18n
"crm.intelligence.prediction.score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32",//no i18n
"crm.lead.prediction.recent.score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.intelligence.prediction.lastconv":"\u0e01\u0e32\u0e23\u0e41\u0e1b\u0e25\u0e07 3 \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.intelligence.prediction.recordsfocus":"\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e08\u0e30\u0e21\u0e38\u0e48\u0e07\u0e40\u0e19\u0e49\u0e19",//no i18n
"crm.intelligence.prediction.slowmoving":"\u0e01\u0e32\u0e23\u0e40\u0e04\u0e25\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e2b\u0e27\u0e0a\u0e49\u0e32",//no i18n
"crm.intelligence.prediction.trend.down":"\u0e41\u0e19\u0e27\u0e42\u0e19\u0e49\u0e21\u0e25\u0e14\u0e25\u0e07\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.label.touched.records":"\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.untouched.records":"\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d",//no i18n
"crm.label.record.action":"\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e01\u0e31\u0e1a\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19",//no i18n
"workflow.rule.view.label.Modified":"\u0e41\u0e01\u0e49\u0e44\u0e02\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.not.modified":"\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e41\u0e01\u0e49\u0e44\u0e02",//no i18n
"crm.label.related.records.action":"\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e01\u0e31\u0e1a\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e02\u0e49\u0e2d\u0e07",//no i18n
"Done":"\u0e40\u0e2a\u0e23\u0e47\u0e08\u0e2a\u0e34\u0e49\u0e19",//no i18n
"crm.label.not.done":"\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e17\u0e33",//no i18n
"sentiment.model":"\u0e04\u0e27\u0e32\u0e21\u0e04\u0e34\u0e14\u0e40\u0e2b\u0e47\u0e19\u0e02\u0e2d\u0e07\u0e2d\u0e35\u0e40\u0e21\u0e25",//no i18n
"sentiment.criteria.count":"\u0e08\u0e33\u0e19\u0e27\u0e19",//no i18n
"sentiment.criteria.percentage":"\u0e23\u0e49\u0e2d\u0e22\u0e25\u0e30",//no i18n
"sentiment.criteria.lastmail":"\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"Chats":"\u0e2a\u0e19\u0e17\u0e19\u0e32",//no i18n
"Attended":"\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.warning.select.filter":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e19\u0e49\u0e2d\u0e22\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e17\u0e35\u0e48\u0e08\u0e30\u0e01\u0e23\u0e2d\u0e07",//no i18n
"crm.lead.prediction.popup.text":"\u0e40\u0e21\u0e37\u0e48\u0e2d\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07\u0e2d\u0e22\u0e39\u0e48\u0e17\u0e35\u0e48 {0} \u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32\u0e04\u0e27\u0e23\u0e2d\u0e22\u0e39\u0e48\u0e23\u0e30\u0e2b\u0e27\u0e48\u0e32\u0e07 {1}",//no i18n
"crm.lead.prediction.popup.final":"\u0e42\u0e1b\u0e23\u0e14\u0e40\u0e1b\u0e25\u0e35\u0e48\u0e22\u0e19\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07\u0e43\u0e2b\u0e49\u0e2a\u0e2d\u0e14\u0e04\u0e25\u0e49\u0e2d\u0e07 \u0e41\u0e25\u0e30\u0e25\u0e2d\u0e07\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07",//no i18n
"crm.custom.field.less.than.to":"\u0e0a\u0e48\u0e27\u0e07 <i>\u0e08\u0e32\u0e01</i> \u0e15\u0e49\u0e2d\u0e07\u0e44\u0e21\u0e48\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32\u0e0a\u0e48\u0e27\u0e07 <i>\u0e16\u0e36\u0e07</i>",//no i18n
"Last\ Activity\ Date":"\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"Contact\ Name":"\u0e0a\u0e37\u0e48\u0e2d {0}",//no i18n
"crm.lead.prediction.tooltip":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07 - \u0e0a\u0e48\u0e27\u0e07\u0e04\u0e30\u0e41\u0e19\u0e19",//no i18n
"crm.website.activity":"\u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21\u0e40\u0e27\u0e47\u0e1a\u0e44\u0e0b\u0e15\u0e4c",//no i18n
"days":"\u0e27\u0e31\u0e19",//no i18n
"weeks":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c",//no i18n
"months":"\u0e40\u0e14\u0e37\u0e2d\u0e19",//no i18n
"crm.condition.in.last":"\u0e43\u0e19\u0e04\u0e23\u0e31\u0e49\u0e07\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"before":"\u0e01\u0e48\u0e2d\u0e19",//no i18n
"crm.label.general.small.after":"\u0e2b\u0e25\u0e31\u0e07",//no i18n
"between":"\u0e23\u0e30\u0e2b\u0e27\u0e48\u0e32\u0e07",//no i18n
"Today":"\u0e27\u0e31\u0e19\u0e19\u0e35\u0e49",//no i18n
"Yesterday":"\u0e40\u0e21\u0e37\u0e48\u0e2d\u0e27\u0e32\u0e19",//no i18n
"crm.thisweek":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e19\u0e35\u0e49",//no i18n
"crm.label.this.month":"\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e19\u0e35\u0e49",//no i18n
"crm.thisyear":"\u0e1b\u0e35\u0e19\u0e35\u0e49",//no i18n
"Last\ Week":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"Last\ Month":"\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.intelligence.prediction.trendup":"\u0e41\u0e19\u0e27\u0e42\u0e19\u0e49\u0e21\u0e2a\u0e39\u0e07\u0e02\u0e36\u0e49\u0e19",//no i18n
"crm.intelligence.prediction.trenddown":"\u0e41\u0e19\u0e27\u0e42\u0e19\u0e49\u0e21\u0e25\u0e14\u0e25\u0e07",//no i18n
"crm.condition.last.30.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 30 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.condition.last.60.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 60 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.condition.last.90.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 90 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.condition.until.now":"\u0e08\u0e19\u0e16\u0e36\u0e07\u0e15\u0e2d\u0e19\u0e19\u0e35\u0e49",//no i18n
"crm.sentiment.Positive":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01",//no i18n
"crm.sentiment.Negative":"\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//no i18n
"sentiment.positiveandnegative":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01\u0e41\u0e25\u0e30\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//no i18n
"sentiment.positiveornegative":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01\u0e2b\u0e23\u0e37\u0e2d\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//no i18n
"sentiment.positiveonly":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"sentiment.negativeonly":"\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.sentiment.Neutral":"\u0e40\u0e09\u0e22\u0e46",//no i18n
"crm.module.name":"\u0e0a\u0e37\u0e48\u0e2d {0}",//no i18n
"Campaign":"\u0e41\u0e04\u0e21\u0e40\u0e1b\u0e0d",//no i18n
"crm.filters.select.campaign.type":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e1b\u0e23\u0e30\u0e40\u0e20\u0e17 {0}",//no i18n
"Advertisement":"\u0e01\u0e32\u0e23\u0e42\u0e06\u0e29\u0e13\u0e32",//no i18n
"Banner\ Ads":"\u0e42\u0e06\u0e29\u0e13\u0e32\u0e1a\u0e19\u0e41\u0e1a\u0e19\u0e40\u0e19\u0e2d\u0e23\u0e4c",//no i18n
"Conference":"\u0e01\u0e32\u0e23\u0e1b\u0e23\u0e30\u0e0a\u0e38\u0e21",//no i18n
"Direct\ mail":"\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e17\u0e32\u0e07\u0e15\u0e23\u0e07",//no i18n
"crm.taskreminder.line8":"\u0e2d\u0e35\u0e40\u0e21\u0e25",//no i18n
"Others":"\u0e2d\u0e37\u0e48\u0e19\u0e46",//no i18n
"Partner":"\u0e1e\u0e32\u0e23\u0e4c\u0e17\u0e40\u0e19\u0e2d\u0e23\u0e4c",//no i18n
"Public\ Relations":"\u0e1b\u0e23\u0e30\u0e0a\u0e32\u0e2a\u0e31\u0e21\u0e1e\u0e31\u0e19\u0e18\u0e4c",//no i18n
"Referral\ Program":"\u0e42\u0e1b\u0e23\u0e41\u0e01\u0e23\u0e21\u0e1c\u0e39\u0e49\u0e41\u0e19\u0e30\u0e19\u0e33",//no i18n
"Telemarketing":"\u0e01\u0e32\u0e23\u0e15\u0e25\u0e32\u0e14\u0e17\u0e32\u0e07\u0e42\u0e17\u0e23\u0e28\u0e31\u0e1e\u0e17\u0e4c",//no i18n
"Trade\ Show":"\u0e01\u0e32\u0e23\u0e41\u0e2a\u0e14\u0e07\u0e2a\u0e34\u0e19\u0e04\u0e49\u0e32",//no i18n
"Webinar":"\u0e01\u0e32\u0e23\u0e2a\u0e31\u0e21\u0e21\u0e19\u0e32\u0e17\u0e32\u0e07\u0e40\u0e27\u0e47\u0e1a",//no i18n
"crm.filters.select.campaign.status":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e2a\u0e16\u0e32\u0e19\u0e30 {0}",//no i18n
"Active":"\u0e40\u0e1b\u0e34\u0e14\u0e17\u0e33\u0e07\u0e32\u0e19\u0e2d\u0e22\u0e39\u0e48",//no i18n
"Complete":"\u0e40\u0e2a\u0e23\u0e47\u0e08\u0e2a\u0e21\u0e1a\u0e39\u0e23\u0e13\u0e4c",//no i18n
"Inactive":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e40\u0e1b\u0e34\u0e14\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19",//no i18n
"Planning":"\u0e01\u0e32\u0e23\u0e27\u0e32\u0e07\u0e41\u0e1c\u0e19",//no i18n
"Activities":"\u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21",//no i18n
"Tasks":"\u0e07\u0e32\u0e19",//no i18n
"Calls":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23",//no i18n
"Tomorrow":"\u0e27\u0e31\u0e19\u0e1e\u0e23\u0e38\u0e48\u0e07\u0e19\u0e35\u0e49",//no i18n
"crm.livedesk.pot.nextdays":"{0} \u0e27\u0e31\u0e19\u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
"Today\ +\ Overdue":"\u0e27\u0e31\u0e19\u0e19\u0e35\u0e49 + \u0e17\u0e35\u0e48\u0e40\u0e25\u0e22\u0e01\u0e33\u0e2b\u0e19\u0e14",//no i18n
"crm.source.user.and.system":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e41\u0e25\u0e30\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"crm.source.user.or.system":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e2b\u0e23\u0e37\u0e2d\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"User":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"crm.source.user.only":"\u0e40\u0e09\u0e1e\u0e32\u0e30\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.source.system.only":"\u0e42\u0e14\u0e22\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"Scheduled":"\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e01\u0e32\u0e23\u0e41\u0e25\u0e49\u0e27",//no i18n
"Attended\ Dialled":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23\u0e2d\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e21\u0e35\u0e04\u0e19\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22",//no i18n
"Unattended\ Dialled":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23\u0e2d\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e21\u0e35\u0e04\u0e19\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22",//no i18n
"Cancelled":"\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.email.isblocked":"\u0e16\u0e39\u0e01\u0e1a\u0e25\u0e47\u0e2d\u0e01",//no i18n
"crm.filter.email.isnotblocked":"\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e1a\u0e25\u0e47\u0e2d\u0e01",//no i18n
"crm.condition.till.today":"\u0e08\u0e19\u0e16\u0e36\u0e07\u0e27\u0e31\u0e19\u0e19\u0e35\u0e49",//no i18n
"crm.recurring.no.months":"{0} \u0e40\u0e14\u0e37\u0e2d\u0e19",//no i18n
"sentiment.criteria.wrongcriteria":"\u0e04\u0e48\u0e32\u0e40\u0e01\u0e13\u0e11\u0e4c\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {0}",//no i18n
"crm.chosen.minimum.input.text":"\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e19\u0e49\u0e2d\u0e22 {0} \u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30",//no i18n
"crm.chosen.searching.text":"\u0e01\u0e33\u0e25\u0e31\u0e07\u0e04\u0e49\u0e19\u0e2b\u0e32...",//no i18n
"is":"\u0e40\u0e1b\u0e47\u0e19",//no i18n
"crm.label.memberstatus.is":"\u0e41\u0e25\u0e30\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01\u0e04\u0e37\u0e2d",//no i18n
"crm.events.duration":"\u0e23\u0e30\u0e22\u0e30\u0e40\u0e27\u0e25\u0e32",//no i18n
"and":"\u0e41\u0e25\u0e30",//no i18n
"crm.title.clear.name":"\u0e25\u0e49\u0e32\u0e07",//no i18n
"zia.last3.help":"\u0e01\u0e32\u0e23\u0e2a\u0e19\u0e17\u0e19\u0e32\u0e23\u0e27\u0e21\u0e16\u0e36\u0e07\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23 \u0e07\u0e32\u0e19, {0}, \u0e2d\u0e35\u0e40\u0e21\u0e25\u0e17\u0e35\u0e48\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a \u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d \u0e01\u0e32\u0e23\u0e40\u0e22\u0e35\u0e48\u0e22\u0e21\u0e0a\u0e21\u0e44\u0e0b\u0e15\u0e4c \u0e02\u0e49\u0e2d\u0e04\u0e34\u0e14\u0e40\u0e2b\u0e47\u0e19\u0e1a\u0e19\u0e2a\u0e37\u0e48\u0e2d\u0e2a\u0e31\u0e07\u0e04\u0e21 \u0e04\u0e33\u0e02\u0e2d\u0e01\u0e32\u0e23\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e08\u0e32\u0e01 Desk",//no i18n
"crm.label.By":"\u0e42\u0e14\u0e22",//no i18n
"crm.label.add.note":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d",//no i18n
"crm.label.simply.by":"\u0e42\u0e14\u0e22",//no i18n
"crm.general.addnote":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d",//no i18n
"crm.general.addtitle":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e0a\u0e37\u0e48\u0e2d\u0e40\u0e23\u0e37\u0e48\u0e2d\u0e07",//no i18n
"crm.label.attach.file":"\u0e41\u0e19\u0e1a\u0e44\u0e1f\u0e25\u0e4c",//no i18n
"crm.button.mass.delete":"\u0e25\u0e1a",//no i18n
"crm.warning.delete.record":"\u0e04\u0e38\u0e13\u0e41\u0e19\u0e48\u0e43\u0e08\u0e2b\u0e23\u0e37\u0e2d\u0e27\u0e48\u0e32\u0e04\u0e38\u0e13\u0e15\u0e49\u0e2d\u0e07\u0e01\u0e32\u0e23\u0e25\u0e1a \"{0}\"",//no i18n
"crm.label.yes":"\u0e43\u0e0a\u0e48",//no i18n
"crm.note.view.previous":"\u0e14\u0e39\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d\u0e01\u0e48\u0e2d\u0e19\u0e2b\u0e19\u0e49\u0e32",//no i18n
"of":"\u0e02\u0e2d\u0e07",//no i18n
"crm.label.notes":"\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d",//no i18n
"crm.note.recent.first":"\u0e25\u0e33\u0e14\u0e31\u0e1a\u0e41\u0e23\u0e01\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.note.recent.last":"\u0e25\u0e33\u0e14\u0e31\u0e1a\u0e2a\u0e38\u0e14\u0e17\u0e49\u0e32\u0e22\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.territory.label.only":"{0} \u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.button.apply.filter":"\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07",//no i18n
"PM":"PM",//no i18n
//start-cx create form keys
"crm.duplicate.value.not.allowed": "\u0e44\u0e21\u0e48\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e04\u0e48\u0e32\u0e17\u0e35\u0e48\u0e0b\u0e49\u0e33\u0e01\u0e31\u0e19",//no i18n
"crm.duplicate.value.available": "\u0e1e\u0e1a {0} \u0e17\u0e35\u0e48\u0e21\u0e35\u0e2d\u0e22\u0e39\u0e48\u0e40\u0e14\u0e34\u0e21\u0e17\u0e35\u0e48\u0e21\u0e35 {1} \u0e40\u0e14\u0e35\u0e22\u0e27\u0e01\u0e31\u0e19",//no i18n
"crm.duplicate.value.available.multiple": "\u0e1e\u0e1a {0} \u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e17\u0e35\u0e48\u0e21\u0e35\u0e2d\u0e22\u0e39\u0e48\u0e40\u0e14\u0e34\u0e21\u0e17\u0e35\u0e48\u0e21\u0e35 {1} \u0e40\u0e14\u0e35\u0e22\u0e27\u0e01\u0e31\u0e19",//no i18n
"crm.custombutton.nobuttons.found": "\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e1b\u0e38\u0e48\u0e21",//no i18n
"crm.custombutton.create.newbutton": "\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e1b\u0e38\u0e48\u0e21",//no i18n
"crm.custombutton.manage.button": "\u0e08\u0e31\u0e14\u0e01\u0e32\u0e23\u0e1b\u0e38\u0e48\u0e21",//no i18n
"crm.custombutton.name": "\u0e0a\u0e37\u0e48\u0e2d",//no i18n
"crm.customize.custombutton.function.desc": "\u0e04\u0e33\u0e2d\u0e18\u0e34\u0e1a\u0e32\u0e22",//no i18n
"crm.custombutton.action": "\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e02\u0e2d\u0e07\u0e1b\u0e38\u0e48\u0e21",//no i18n
"crm.custombutton.empty.field.values": "\u0e1e\u0e1a\u0e04\u0e48\u0e32\u0e27\u0e48\u0e32\u0e07\u0e40\u0e1b\u0e25\u0e48\u0e32\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e15\u0e48\u0e2d\u0e44\u0e1b\u0e19\u0e35\u0e49:",//no i18n
"crm.custombutton.proceed.action": "\u0e04\u0e38\u0e13\u0e41\u0e19\u0e48\u0e43\u0e08\u0e2b\u0e23\u0e37\u0e2d\u0e27\u0e48\u0e32\u0e15\u0e49\u0e2d\u0e07\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e15\u0e48\u0e2d",//no i18n
"zb.common.Cancel": "\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01",//no i18n
"crm.yes.proceed": "\u0e43\u0e0a\u0e48 \u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e15\u0e48\u0e2d",//no i18n
"crm.label.module.merge": "\u0e1c\u0e2a\u0e32\u0e19 {0}",//no i18n
"crm.view.record": "\u0e14\u0e39 {0}",//no i18n
"crm.tpi.ctiapi.config.choosefile": "\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e1f\u0e25\u0e4c",//no i18n
"crm.label.subform.addrows": "\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e41\u0e16\u0e27",//no i18n
"crm.button.save&new": "\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e41\u0e25\u0e30\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e43\u0e2b\u0e21\u0e48",//no i18n
//end-cx create form keys
"crm.mb.newversion.msg4":"\u0e15\u0e01\u0e25\u0e07 \u0e40\u0e02\u0e49\u0e32\u0e43\u0e08\u0e41\u0e25\u0e49\u0e27",//no i18n
"Jan":"\u0e21.\u0e04.",//no i18n
"Feb":"\u0e01.\u0e1e.",//no i18n
"Mar":"\u0e21\u0e35.\u0e04.",//no i18n
"Apr":"\u0e40\u0e21.\u0e22.",//no i18n
"Jun":"\u0e21\u0e34.\u0e22.",//no i18n
"Jul":"\u0e01.\u0e04.",//no i18n
"Aug":"\u0e2a.\u0e04.",//no i18n
"Sep":"\u0e01.\u0e22.",//no i18n
"Oct":"\u0e15.\u0e04.",//no i18n
"Nov":"\u0e1e.\u0e22.",//no i18n
"Dec":"\u0e18.\u0e04.",//no i18n
"crm.label.unmapped.stages":"\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e21\u0e35\u0e1a\u0e31\u0e0d\u0e0a\u0e35",//no i18n
"crm.wf.summary.label.ShowInstantActions":"\u0e41\u0e2a\u0e14\u0e07\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21",//no i18n
"crm.wf.summary.label.HideInstantActions":"\u0e41\u0e2a\u0e14\u0e07\u0e19\u0e49\u0e2d\u0e22\u0e25\u0e07",//no i18n
"crm.label.account.created":"{0} \u0e43\u0e2b\u0e21\u0e48\u0e08\u0e30\u0e16\u0e39\u0e01\u0e2a\u0e23\u0e49\u0e32\u0e07",//no i18n
"crm.krp.no.records.found":"\u0e44\u0e21\u0e48\u0e1e\u0e1a {0}",//no i18n
"crm.module.new":"\u0e2a\u0e23\u0e49\u0e32\u0e07 {0}",//no i18n
"crm.label.view":"\u0e14\u0e39",//no i18n
"crm.nsocial.customers":"\u0e25\u0e39\u0e01\u0e04\u0e49\u0e32",//no i18n
"crm.nsocial.open.potential":"{0} \u0e17\u0e35\u0e48\u0e40\u0e1b\u0e34\u0e14\u0e2d\u0e22\u0e39\u0e48",//no i18n
"crm.nsocial.lead.contact":"{0}/{1}",//no i18n
"Others":"\u0e2d\u0e37\u0e48\u0e19\u0e46",//no i18n
"crm.field.length.check":"\u0e04\u0e48\u0e32 {0} \u0e40\u0e01\u0e34\u0e19\u0e02\u0e35\u0e14\u0e08\u0e33\u0e01\u0e31\u0e14\u0e04\u0e27\u0e32\u0e21\u0e22\u0e32\u0e27\u0e2a\u0e39\u0e07\u0e2a\u0e38\u0e14",//no i18n
"crm.lower.now":"\u0e15\u0e2d\u0e19\u0e19\u0e35\u0e49",//no i18n
"crm.time.min.ago":"{0} \u0e19\u0e32\u0e17\u0e35\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.time.mins.ago":"{0} \u0e19\u0e32\u0e17\u0e35\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.time.hr.ago":"{0} \u0e0a\u0e31\u0e48\u0e27\u0e42\u0e21\u0e07\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.time.hrs.ago":"{0} \u0e0a\u0e31\u0e48\u0e27\u0e42\u0e21\u0e07\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"AllUsers":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//no i18n
"crm.label.search":"\u0e04\u0e49\u0e19\u0e2b\u0e32",//no i18n
"crm.api.filterby":"\u0e01\u0e23\u0e2d\u0e07\u0e15\u0e32\u0e21",//no i18n
"crm.customview.nofields.found":"--\u0e44\u0e21\u0e48\u0e21\u0e35\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e17\u0e35\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e19--",//no i18n
"crm.setup.system.ziarecommendation":"\u0e01\u0e32\u0e23\u0e41\u0e19\u0e30\u0e19\u0e33",//no i18n
"crm.filter.label.all.products":"{0} \u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//no i18n
"crm.filter.label.select.products":"{0} \u0e17\u0e35\u0e48\u0e40\u0e25\u0e37\u0e2d\u0e01",//no i18n
"crm.reviewprocess.smart.filter":"\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e43\u0e19\u0e01\u0e23\u0e30\u0e1a\u0e27\u0e19\u0e01\u0e32\u0e23\u0e17\u0e1a\u0e17\u0e27\u0e19",//no i18n
"crm.dashboard.sharing.empty.value.alert":"\u0e42\u0e1b\u0e23\u0e14\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e04\u0e48\u0e32",//no i18n
"crm.segmentation.segment.score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e2a\u0e48\u0e27\u0e19",//no i18n
"crm.filter.label.in":"\u0e2d\u0e34\u0e07\u0e15\u0e32\u0e21",//no i18n
"crm.filter.label.and.purchase.in":"\u0e41\u0e25\u0e30\u0e21\u0e35\u0e41\u0e19\u0e27\u0e42\u0e19\u0e49\u0e21\u0e17\u0e35\u0e48\u0e08\u0e30\u0e0b\u0e37\u0e49\u0e2d\u0e43\u0e19",//no i18n
"crm.filter.label.last.purchased":"\u0e41\u0e25\u0e30\u0e40\u0e1e\u0e34\u0e48\u0e07\u0e44\u0e14\u0e49\u0e21\u0e32",//no i18n
"crm.filter.label.a.day":"\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e27\u0e31\u0e19",//no i18n
"crm.filter.label.a.week":"\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c",//no i18n
"crm.filter.label.a.month":"\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e40\u0e14\u0e37\u0e2d\u0e19",//no i18n
"crm.cal.custom":"\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e40\u0e2d\u0e07",//no i18n
"crm.mb.field.common.empt":"\u0e04\u0e48\u0e32\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e27\u0e48\u0e32\u0e07\u0e40\u0e1b\u0e25\u0e48\u0e32",//no i18n
"crm.chosen.error.loading.text":"\u0e02\u0e2d\u0e2d\u0e20\u0e31\u0e22 \u0e40\u0e23\u0e32\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e42\u0e2b\u0e25\u0e14\u0e1c\u0e25\u0e25\u0e31\u0e1e\u0e18\u0e4c\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13",//no i18n
"crm.filter.label.firstbuy":"\u0e04\u0e23\u0e31\u0e49\u0e07\u0e41\u0e23\u0e01",//no i18n
"crm.filter.label.cwbab":"\u0e04\u0e27\u0e32\u0e21\u0e02\u0e36\u0e49\u0e19\u0e15\u0e48\u0e2d\u0e01\u0e31\u0e19",//no i18n
"crm.filter.label.fbt":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19",//no i18n
"crm.filter.label.rebuy":"\u0e17\u0e33\u0e0b\u0e49\u0e33",//no i18n
"crm.filter.label.nextbuy":"\u0e25\u0e33\u0e14\u0e31\u0e1a",//no i18n
"crm.mxnlookup.select":"\u0e21\u0e2d\u0e1a\u0e2b\u0e21\u0e32\u0e22 {0}",//no i18n
"crm.lookup.chooserecord":"\u0e40\u0e25\u0e37\u0e2d\u0e01 {0}",//no i18n
"crm.record.selected":"{0} \u0e17\u0e35\u0e48\u0e40\u0e25\u0e37\u0e2d\u0e01",//no i18n
"crm.module.empty.message":"\u0e44\u0e21\u0e48\u0e1e\u0e1a {0}",//no i18n
"crm.mxnlookup.selected":"{0} \u0e17\u0e35\u0e48\u0e21\u0e2d\u0e1a\u0e2b\u0e21\u0e32\u0e22",//no i18n
"crm.security.error":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e21\u0e35\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e4c\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e40\u0e1e\u0e35\u0e22\u0e07\u0e1e\u0e2d\u0e43\u0e2b\u0e49\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e01\u0e23\u0e30\u0e1a\u0e27\u0e19\u0e01\u0e32\u0e23\u0e19\u0e35\u0e49 \u0e42\u0e1b\u0e23\u0e14\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d\u0e01\u0e31\u0e1a\u0e1c\u0e39\u0e49\u0e14\u0e39\u0e41\u0e25\u0e23\u0e30\u0e1a\u0e1a\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13",//no i18n
"crm.label.creator.noPermission":"\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e4c\u0e16\u0e39\u0e01\u0e1b\u0e0f\u0e34\u0e40\u0e2a\u0e18",//no i18n
"crm.segmentation.recency":"\u0e04\u0e27\u0e32\u0e21\u0e43\u0e2b\u0e21\u0e48",//no i18n
"crm.segmentation.frequency":"\u0e04\u0e27\u0e32\u0e21\u0e16\u0e35\u0e48",//no i18n
"crm.segmentation.monetary":"\u0e01\u0e32\u0e23\u0e40\u0e07\u0e34\u0e19",//no i18n
"crm.smartfilter.related.module.msg":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e21\u0e2d\u0e14\u0e39\u0e25\u0e17\u0e35\u0e48\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e02\u0e49\u0e2d\u0e07\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 3 \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",//no i18n
"crm.smartfilter.related.module.msg1":"(\u0e15.\u0e22.: \u0e2d\u0e35\u0e40\u0e21\u0e25 \u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21 \u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d)",//no i18n
"crm.smartfilter.related.module.msg2":"\u0e0a\u0e48\u0e27\u0e07\u0e40\u0e27\u0e25\u0e32\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e27\u0e48\u0e32\u0e07\u0e40\u0e1b\u0e25\u0e48\u0e32", //no i18n
"crm.label.timeZone": "\u0e40\u0e02\u0e15\u0e40\u0e27\u0e25\u0e32", //NO I18n
"crm.label.insufficient.privileges": "\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e4c\u0e44\u0e21\u0e48\u0e40\u0e1e\u0e35\u0e22\u0e07\u0e1e\u0e2d\u0e43\u0e19\u0e01\u0e32\u0e23\u0e17\u0e33\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e19\u0e35\u0e49 \u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d\u0e1c\u0e39\u0e49\u0e14\u0e39\u0e41\u0e25\u0e23\u0e30\u0e1a\u0e1a\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13", //NO I18n
"crm.filter.header.secton.system": "\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07\u0e17\u0e35\u0e48\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e42\u0e14\u0e22\u0e23\u0e30\u0e1a\u0e1a", //NO I18N
"crm.filter.header.secton.fields": "\u0e01\u0e23\u0e2d\u0e07\u0e15\u0e32\u0e21\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25", //NO I18N
"crm.createfield.calcinfo.new" : "\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e19\u0e35\u0e49\u0e17\u0e33\u0e2b\u0e19\u0e49\u0e32\u0e17\u0e35\u0e48\u0e40\u0e1b\u0e47\u0e19\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e04\u0e33\u0e19\u0e27\u0e13\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e19\u0e34\u0e1e\u0e08\u0e19\u0e4c\u0e17\u0e35\u0e48\u0e04\u0e38\u0e13\u0e1b\u0e49\u0e2d\u0e19</br> <b>\u0e40\u0e0a\u0e48\u0e19 20+20</b> \u0e08\u0e30\u0e43\u0e2b\u0e49\u0e1c\u0e25 <b>40</b> \u0e42\u0e14\u0e22\u0e2d\u0e31\u0e15\u0e42\u0e19\u0e21\u0e31\u0e15\u0e34",//No i18n
"crm.lable.read.only" : "\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e2d\u0e48\u0e32\u0e19\u0e2d\u0e22\u0e32\u0e07\u0e40\u0e14\u0e35\u0e22\u0e27",//No i18n
"crm.column.sort.asc" : "\u0e19\u0e49\u0e2d\u0e22\u0e44\u0e1b\u0e2b\u0e32\u0e21\u0e32\u0e01",//No I18n
"crm.column.sort.desc" : "\u0e21\u0e32\u0e01\u0e44\u0e1b\u0e2b\u0e32\u0e19\u0e49\u0e2d\u0e22",//No i18n
	"crm.column.unsort" : "\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01\u0e01\u0e32\u0e23\u0e40\u0e23\u0e35\u0e22\u0e07\u0e25\u0e33\u0e14\u0e31\u0e1a",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "\u0e41\u0e0a\u0e23\u0e4c\u0e44\u0e1b\u0e22\u0e31\u0e07\u0e25\u0e39\u0e01\u0e04\u0e49\u0e32", //NO I18N
"crm.label.edited":"\u0e41\u0e01\u0e49\u0e44\u0e02\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.edited.on":"\u0e16\u0e39\u0e01\u0e41\u0e01\u0e49\u0e44\u0e02\u0e40\u0e21\u0e37\u0e48\u0e2d",//no i18n
"crm.message.limit.exceed": "\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e44\u0e21\u0e48\u0e40\u0e01\u0e34\u0e19 {0} \u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a {1}", //NO I18N
"custmr.prtl.notes.shrd.with.custmr": "\u0e17\u0e35\u0e48\u0e41\u0e0a\u0e23\u0e4c\u0e01\u0e31\u0e1a\u0e25\u0e39\u0e01\u0e04\u0e49\u0e32", //NO I18N
"crm.button.ok" : "\u0e15\u0e01\u0e25\u0e07", //NO I18N
"crm.role.already.selected" : "\u0e21\u0e35\u0e01\u0e32\u0e23\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e1a\u0e17\u0e1a\u0e32\u0e17\u0e19\u0e35\u0e49\u0e44\u0e27\u0e49\u0e41\u0e25\u0e49\u0e27", //no i18n
"crm.user.deleted": "\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e16\u0e39\u0e01\u0e25\u0e1a\u0e41\u0e25\u0e49\u0e27",  //NO I18N
"crm.account.closed": "\u0e1a\u0e31\u0e0d\u0e0a\u0e35\u0e19\u0e35\u0e49\u0e16\u0e39\u0e01\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27",  //NO I18N
"crm.start.chat": "\u0e40\u0e23\u0e34\u0e48\u0e21\u0e01\u0e32\u0e23\u0e2a\u0e19\u0e17\u0e19\u0e32",  //NO I18N
"crm.start.call": "\u0e40\u0e23\u0e34\u0e48\u0e21\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23",  //NO I18N
"crm.start.video.call": "\u0e40\u0e23\u0e34\u0e48\u0e21\u0e01\u0e32\u0e23\u0e2a\u0e19\u0e17\u0e19\u0e32\u0e17\u0e32\u0e07\u0e27\u0e34\u0e14\u0e35\u0e42\u0e2d",  //NO I18N
"crm.recipient.invalid.email" : "\u0e1e\u0e1a\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", //NO I18N
"crm.recipient.add.recipient" : "\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e1c\u0e39\u0e49\u0e23\u0e31\u0e1a\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21", //NO I18N

"crm.label.scoring.rules":"\u0e01\u0e0e\u0e01\u0e32\u0e23\u0e43\u0e2b\u0e49\u0e04\u0e30\u0e41\u0e19\u0e19",//NO I18N
"Score":"\u0e04\u0e30\u0e41\u0e19\u0e19",//NO I18N
"Positive Score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01",//NO I18N
"Negative Score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//NO I18N
"Touch Point Score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e08\u0e38\u0e14\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d",//NO I18N
"Positive Touch Point Score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e08\u0e38\u0e14\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01",//NO I18N
"Negative Touch Point Score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e08\u0e38\u0e14\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//NO I18N
"crm.label.type.minutes": "\u0e1e\u0e34\u0e21\u0e1e\u0e4c\u0e43\u0e19\u0e2b\u0e19\u0e48\u0e27\u0e22\u0e19\u0e32\u0e17\u0e35", //NO I18N

"is\ OPEN":"\u0e21\u0e35\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e40\u0e1b\u0e34\u0e14\u0e2d\u0e22\u0e39\u0e48",//no i18n
"is\ WON":"\u0e21\u0e35\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e44\u0e14\u0e49\u0e40\u0e1b\u0e47\u0e19\u0e25\u0e39\u0e01\u0e04\u0e49\u0e32",//no i18n
"is\ LOST":"\u0e21\u0e35\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2a\u0e39\u0e0d\u0e40\u0e2a\u0e35\u0e22\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.potential.all.open":"\u0e02\u0e31\u0e49\u0e19\u0e15\u0e2d\u0e19\u0e17\u0e35\u0e48\u0e40\u0e1b\u0e34\u0e14\u0e2d\u0e22\u0e39\u0e48\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//no i18n
"crm.potential.all.won":"\u0e02\u0e31\u0e49\u0e19\u0e15\u0e2d\u0e19\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e40\u0e1b\u0e47\u0e19\u0e25\u0e39\u0e01\u0e04\u0e49\u0e32\u0e17\u0e35\u0e48\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//no i18n

"crm.potential.all.lost":"\u0e02\u0e31\u0e49\u0e19\u0e15\u0e2d\u0e19\u0e01\u0e32\u0e23\u0e2a\u0e39\u0e0d\u0e40\u0e2a\u0e35\u0e22\u0e44\u0e1b\u0e17\u0e35\u0e48\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//no i18n
"crm.campaign.member.status" : "\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01",//no i18n
"crm.dashboard.select.type" : "\u0e40\u0e25\u0e37\u0e2d\u0e01 {0}",//no i18n
"crm.campaign.service.status":"\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",//no i18n

"crm.label.addColumn":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c",//no i18n
"crm.button.clear.filter":"\u0e1b\u0e34\u0e14\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07",//no i18n
"crm.button.show.filter":"\u0e41\u0e2a\u0e14\u0e07\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07",//no i18n
"crm.las.error.user.maxlimit":"\u0e04\u0e38\u0e13\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e44\u0e14\u0e49\u0e2a\u0e39\u0e07\u0e2a\u0e38\u0e14 20 \u0e04\u0e19\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.las.error.picklist.maxlimit":"\u0e04\u0e38\u0e13\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e14\u0e49\u0e2a\u0e39\u0e07\u0e2a\u0e38\u0e14 20 \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n

"crm.fileuploader.message.responseerror": "\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14\u0e25\u0e49\u0e21\u0e40\u0e2b\u0e25\u0e27", //NO I18N
"crm.storage.create.error": "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e43\u0e2b\u0e21\u0e48 \u0e40\u0e19\u0e37\u0e48\u0e2d\u0e07\u0e08\u0e32\u0e01\u0e04\u0e38\u0e13\u0e43\u0e0a\u0e49\u0e16\u0e36\u0e07\u0e02\u0e35\u0e14\u0e08\u0e33\u0e01\u0e31\u0e14\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48\u0e40\u0e01\u0e47\u0e1a\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e2a\u0e39\u0e07\u0e2a\u0e38\u0e14\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e41\u0e25\u0e49\u0e27", //NO I18N
"crm.storage.create.error.client": "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e43\u0e2b\u0e21\u0e48 \u0e40\u0e19\u0e37\u0e48\u0e2d\u0e07\u0e08\u0e32\u0e01\u0e1c\u0e39\u0e49\u0e14\u0e39\u0e41\u0e25\u0e23\u0e30\u0e1a\u0e1a\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e43\u0e0a\u0e49\u0e16\u0e36\u0e07\u0e02\u0e35\u0e14\u0e08\u0e33\u0e01\u0e31\u0e14\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48\u0e40\u0e01\u0e47\u0e1a\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e2a\u0e39\u0e07\u0e2a\u0e38\u0e14\u0e41\u0e25\u0e49\u0e27 \u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d {0} \u0e40\u0e1e\u0e37\u0e48\u0e2d\u0e41\u0e01\u0e49\u0e44\u0e02\u0e1b\u0e31\u0e0d\u0e2b\u0e32\u0e19\u0e35\u0e49", //NO I18N
"crm.storage.avail.info": "({0} \u0e04\u0e07\u0e40\u0e2b\u0e25\u0e37\u0e2d\u0e08\u0e32\u0e01 {1})", //NO I18N
"crm.storage.error.key.manage": "\u0e08\u0e31\u0e14\u0e01\u0e32\u0e23\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48\u0e40\u0e01\u0e47\u0e1a\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13", //NO I18N
"Records": "\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19", //NO I18N
"crm.zcampaign.test.email.input.placeholder" : "\u0e1b\u0e49\u0e2d\u0e19\u0e17\u0e35\u0e48\u0e2d\u0e22\u0e39\u0e48\u0e2d\u0e35\u0e40\u0e21\u0e25", //NO I18N
"crm.workflow.alert.additional.recipients" : "\u0e1c\u0e39\u0e49\u0e23\u0e31\u0e1a\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21", //NO I18N
"crm.workflow.alert.type.otherEmails" : "\u0e43\u0e0a\u0e49\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e2b\u0e21\u0e32\u0e22\u0e08\u0e38\u0e25\u0e20\u0e32\u0e04\u0e40\u0e1e\u0e37\u0e48\u0e2d\u0e04\u0e31\u0e48\u0e19\u0e17\u0e35\u0e48\u0e2d\u0e22\u0e39\u0e48\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21", //NO I18N
"crm.related.contact.account" : "{0} \u0e17\u0e35\u0e48\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e02\u0e49\u0e2d\u0e07\u0e01\u0e31\u0e1a {1}",//No I18n
	"crm.allcontact.show" : "{0} \u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//No I18n
	"crm.button.mass.show" : "\u0e41\u0e2a\u0e14\u0e07",//No I18n
"crm.msg.custom.view.not.replied" : "\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21\u0e17\u0e35\u0e48\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e15\u0e2d\u0e1a\u0e01\u0e25\u0e31\u0e1a", //NO I18N
	"crm.msg.custom.view.replied" : "\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21\u0e17\u0e35\u0e48\u0e15\u0e2d\u0e1a\u0e01\u0e25\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//NO I18N
	"crm.workflow.select.recipients" : "\u0e1c\u0e39\u0e49\u0e23\u0e31\u0e1a" ,//NO I18N
	"crm.custom.module.no.profile.selected.alert1":"\u0e42\u0e1b\u0e23\u0e14\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e19\u0e49\u0e2d\u0e22\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e42\u0e1b\u0e23\u0e44\u0e1f\u0e25\u0e4c",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "\u0e02\u0e2d\u0e2d\u0e20\u0e31\u0e22 \u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e19\u0e33\u0e42\u0e1b\u0e23\u0e44\u0e1f\u0e25\u0e4c\u0e40\u0e23\u0e34\u0e48\u0e21\u0e15\u0e49\u0e19\u0e2d\u0e2d\u0e01",//NO I18N
	"crm.inv.label.add.emails" : "\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e2d\u0e35\u0e40\u0e21\u0e25", //NO I18N
	"crm.prediction.analytics.filter.year":"\u0e1b\u0e35\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
	"Previous\ FY":"FY \u0e01\u0e48\u0e2d\u0e19\u0e2b\u0e19\u0e49\u0e32",//no i18n
	"Current\ FY":"FY \u0e1b\u0e31\u0e08\u0e08\u0e38\u0e1a\u0e31\u0e19",//no i18n
	"Next\ FY":"FY \u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
	"Current\ FQ":"FQ \u0e1b\u0e31\u0e08\u0e08\u0e38\u0e1a\u0e31\u0e19",//no i18n
	"Next\ FQ":"FQ \u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
	"Previous\ FQ":"FQ \u0e01\u0e48\u0e2d\u0e19\u0e2b\u0e19\u0e49\u0e32",//no i18n
	"crm.picklist.sample.text":"\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21\u0e15\u0e31\u0e27\u0e2d\u0e22\u0e48\u0e32\u0e07",//no i18n
	"crm.more.colors":"\u0e2a\u0e35\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21",//no i18n
	"crm.button.back.alone":"\u0e22\u0e49\u0e2d\u0e19\u0e01\u0e25\u0e31\u0e1a",//no i18n
	"crm.field.label.email":"\u0e2d\u0e35\u0e40\u0e21\u0e25",//no i18n
	"crm.zia.nba.feature.label":"\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e17\u0e35\u0e48\u0e14\u0e35\u0e17\u0e35\u0e48\u0e2a\u0e38\u0e14\u0e04\u0e23\u0e31\u0e49\u0e07\u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
	"Meeting":"\u0e01\u0e32\u0e23\u0e1b\u0e23\u0e30\u0e0a\u0e38\u0e21",//no i18n
	"crm.gdpr.notavailable.field":"\u0e44\u0e21\u0e48\u0e1e\u0e23\u0e49\u0e2d\u0e21\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19",//no i18n
	"crm.setup.system.ziasimilarity":"\u0e01\u0e32\u0e23\u0e41\u0e19\u0e30\u0e19\u0e33\u0e04\u0e27\u0e32\u0e21\u0e04\u0e25\u0e49\u0e32\u0e22\u0e04\u0e25\u0e36\u0e07",//no i18n
	"crm.gdpr.notavailable.field":"\u0e44\u0e21\u0e48\u0e1e\u0e23\u0e49\u0e2d\u0e21\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19",//no i18n
	"crm.label.simply.in":"\u0e43\u0e19",//no i18n
	"crm.filter.label.all.products":"{0} \u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "\u0e40\u0e0b\u0e47\u0e01\u0e40\u0e21\u0e19\u0e15\u0e4c", // NO I18N
	"abm.segment.names": "\u0e0a\u0e37\u0e48\u0e2d\u0e40\u0e0b\u0e47\u0e01\u0e40\u0e21\u0e19\u0e15\u0e4c", // NO I18N
	"abm.abm.segment.name": "\u0e0a\u0e37\u0e48\u0e2d\u0e40\u0e0b\u0e47\u0e01\u0e40\u0e21\u0e19\u0e15\u0e4c ABM", // NO I18N
	"abm.segmentation.techniques": "\u0e40\u0e17\u0e04\u0e19\u0e34\u0e04\u0e01\u0e32\u0e23\u0e41\u0e1a\u0e48\u0e07\u0e40\u0e0b\u0e47\u0e01\u0e40\u0e21\u0e19\u0e15\u0e4c ABM", // NO I18N
	"abm.filters": "\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07 ABM", // NO I18N
	"abm.filters.info": "\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07\u0e40\u0e2b\u0e25\u0e48\u0e32\u0e19\u0e35\u0e49\u0e08\u0e30\u0e41\u0e2a\u0e14\u0e07\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e2d\u0e34\u0e07\u0e15\u0e32\u0e21 {0} \u0e17\u0e35\u0e48\u0e40\u0e0a\u0e37\u0e48\u0e2d\u0e21\u0e42\u0e22\u0e07\u0e01\u0e31\u0e1a {1}", // NO I18N
	"abm.rfm.label": "\u0e1b\u0e49\u0e32\u0e22\u0e0a\u0e37\u0e48\u0e2d RFM", // NO I18N
	"abm.firmographics.label": "\u0e1b\u0e49\u0e32\u0e22\u0e0a\u0e37\u0e48\u0e2d\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e40\u0e0a\u0e34\u0e07\u0e2d\u0e07\u0e04\u0e4c\u0e01\u0e23" ,// NO I18N
	"abm.recommendation.label": "\u0e1b\u0e49\u0e32\u0e22\u0e0a\u0e37\u0e48\u0e2d\u0e01\u0e32\u0e23\u0e41\u0e19\u0e30\u0e19\u0e33", // NO I18N
	"abm.voc.label": "\u0e1b\u0e49\u0e32\u0e22\u0e0a\u0e37\u0e48\u0e2d VOC", // NO I18N
	"abm.engagement.label": "\u0e1b\u0e49\u0e32\u0e22\u0e0a\u0e37\u0e48\u0e2d\u0e01\u0e32\u0e23\u0e21\u0e35\u0e2a\u0e48\u0e27\u0e19\u0e23\u0e48\u0e27\u0e21", // NO I18N
	"abm.overall.score": "\u0e04\u0e30\u0e41\u0e19\u0e19\u0e42\u0e14\u0e22\u0e23\u0e27\u0e21", // NO I18N
	"abm.mood.score": "\u0e04\u0e30\u0e41\u0e19\u0e19\u0e2d\u0e32\u0e23\u0e21\u0e13\u0e4c", // NO I18N
	"abm.value.score": "\u0e04\u0e30\u0e41\u0e19\u0e19\u0e04\u0e38\u0e13\u0e04\u0e48\u0e32", // NO I18N
	"abm.engagement.score": "\u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e21\u0e35\u0e2a\u0e48\u0e27\u0e19\u0e23\u0e48\u0e27\u0e21", // NO I18N
	"abm.rfm.score": "\u0e04\u0e30\u0e41\u0e19\u0e19 RFM", // NO I18N
	"crm.customview.pin.column":"\u0e1b\u0e31\u0e01\u0e2b\u0e21\u0e38\u0e14\u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c",//no i18n
"crm.customview.unpin.column":"\u0e16\u0e2d\u0e19\u0e1b\u0e31\u0e01\u0e2b\u0e21\u0e38\u0e14\u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c",//no i18n
"crm.column.list.max.group.limit" : "" ,//no i18n
	"crux.custom.field.greater.than.equalto":"{0} จะต้องมากกว่าหรือเท่ากับ {1}",
	"crux.users.selected.plural" : "{0} ผู้ใช้ที่เลือก",
	"crux.user.selected.singular" :"{0} ผู้ใช้ที่เลือก",
	"crux.criteria.empty.secondaryfield.module" : "ไม่พบเขตข้อมูล {0} ที่ตรงกันในมอดูล {1}",
	"crux.criteria.empty.secondaryfield" : "ไม่มีเขตข้อมูล {0} อื่นๆ ที่ใช้ได้สำหรับการเปรียบเทียบ โปรดป้อนค่าเพื่อเปรียบเทียบ",
	"crux.logged.in.role.definition" : "บทบาทผู้ใช้ที่เริ่มการดำเนินการของระเบียน",
	"zia.similarity.smartfilter.score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e04\u0e27\u0e32\u0e21\u0e04\u0e25\u0e49\u0e32\u0e22\u0e04\u0e25\u0e36\u0e07",//no i18n
	"zia.similarity.smartfilter.records":"\u0e04\u0e25\u0e49\u0e32\u0e22\u0e04\u0e25\u0e36\u0e07\u0e01\u0e31\u0e1a {0} \u0e02\u0e2d\u0e07",//no i18n
	"zia.similarity.smartfilter.records.search":"\u0e41\u0e2a\u0e14\u0e07\u0e04\u0e27\u0e32\u0e21\u0e04\u0e25\u0e49\u0e32\u0e22\u0e04\u0e25\u0e36\u0e07\u0e01\u0e31\u0e1a {0} \u0e02\u0e2d\u0e07",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01 {1}\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {0} \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} จะต้องมากกว่าหรือเท่ากับ {1}",
	"crux.users.selected.plural" : "{0} ผู้ใช้ที่เลือก",
	"crux.user.selected.singular" :"{0} ผู้ใช้ที่เลือก",
	"crux.criteria.empty.secondaryfield.module" : "ไม่พบเขตข้อมูล {0} ที่ตรงกันในมอดูล {1}",
	"crux.criteria.empty.secondaryfield" : "ไม่มีเขตข้อมูล {0} อื่นๆ ที่ใช้ได้สำหรับการเปรียบเทียบ โปรดป้อนค่าเพื่อเปรียบเทียบ",
	"crux.logged.in.role.definition" : "บทบาทผู้ใช้ที่เริ่มการดำเนินการของระเบียน",
	"crux.max.limit.unselect" : "คุณไม่สามารถยกเลิกเลือกมากกว่า {0} {1}", //No I18N
	"crux.existing.tag" : "\u201d{0}\u201c ถูกเลือกอยู่แล้ว", //NO I18N
	"crm.label.true" : "\u0e08\u0e23\u0e34\u0e07",//NO I18N
	"crm.label.false" : "\u0e40\u0e17\u0e47\u0e08",//NO I18N
	"crm.record.locked" : "\u0e16\u0e39\u0e01\u0e25\u0e47\u0e2d\u0e01",//NO I18N
	"crm.filter.number.range":"\u0e04\u0e48\u0e32\u0e08\u0e32\u0e01 {0}-{1}",//no i18n
	"crm.smartfilter.picklist.options.msg":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e19\u0e35\u0e49\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 10 \u0e04\u0e48\u0e32\u0e17\u0e35\u0e48\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e27\u0e49",//no i18n
	"crm.chosen.maximum.campaigns.selected":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01 {0}\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 5 \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01 {1}\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {0} \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",//no i18n
	"crm.best.time.column.label":"\u0e40\u0e27\u0e25\u0e32\u0e17\u0e35\u0e48\u0e14\u0e35\u0e17\u0e35\u0e48\u0e2a\u0e38\u0e14\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e01\u0e32\u0e23\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d",//no i18n
	"crm.competitoralert.mentioned.in.email":"\u0e16\u0e39\u0e01\u0e01\u0e25\u0e48\u0e32\u0e27\u0e16\u0e36\u0e07\u0e43\u0e19<b>\u0e2d\u0e35\u0e40\u0e21\u0e25</b>",//no i18n
	"crm.competitoralert.with.sentiment":"\u0e1e\u0e23\u0e49\u0e2d\u0e21\u0e04\u0e27\u0e32\u0e21\u0e23\u0e39\u0e49\u0e2a\u0e36\u0e01",//no i18n
	"crm.competitoralert.name.error":"\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19\u0e04\u0e48\u0e32\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e0a\u0e37\u0e48\u0e2d\u0e04\u0e39\u0e48\u0e41\u0e02\u0e48\u0e07",//no i18n
	"crm.competitoralert.date.error":"\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e01\u0e32\u0e23\u0e01\u0e25\u0e48\u0e32\u0e27\u0e16\u0e36\u0e07\u0e04\u0e39\u0e48\u0e41\u0e02\u0e48\u0e07\u0e43\u0e19\u0e2d\u0e35\u0e40\u0e21\u0e25",//no i18n
	"crm.competitoralert.sentiment.error":"\u0e42\u0e1b\u0e23\u0e14\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e04\u0e27\u0e32\u0e21\u0e23\u0e39\u0e49\u0e2a\u0e36\u0e01",//no i18n
	"crm.competitor.name":"\u0e04\u0e39\u0e48\u0e41\u0e02\u0e48\u0e07",
"crm.lookup.advance.error.msg" : "\u0e21\u0e35\u0e02\u0e49\u0e2d\u0e1c\u0e34\u0e14\u0e1e\u0e25\u0e32\u0e14\u0e1a\u0e32\u0e07\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e40\u0e01\u0e34\u0e14\u0e02\u0e36\u0e49\u0e19\u0e43\u0e19\u0e02\u0e13\u0e30\u0e1b\u0e23\u0e31\u0e1a\u0e43\u0e0a\u0e49\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07 \u0e23\u0e35\u0e40\u0e1f\u0e23\u0e0a\u0e2b\u0e19\u0e49\u0e32\u0e41\u0e25\u0e49\u0e27\u0e25\u0e2d\u0e07\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07",
	"Contact\ Name" : "\u0e0a\u0e37\u0e48\u0e2d {0}",
	"Lead\ Name" : "\u0e0a\u0e37\u0e48\u0e2d {0}",
	"-None-" : "\u0e44\u0e21\u0e48\u0e21\u0e35",
	"January" : "\u0e21\u0e01\u0e23\u0e32\u0e04\u0e21",
	"February":"\u0e01\u0e38\u0e21\u0e20\u0e32\u0e1e\u0e31\u0e19\u0e18\u0e4c",
	"March":"\u0e21\u0e35\u0e19\u0e32\u0e04\u0e21",
	"April":"\u0e40\u0e21\u0e29\u0e32\u0e22\u0e19",
	"May":"\u0e1e\u0e24\u0e29\u0e20\u0e32\u0e04\u0e21",
	"June":"\u0e21\u0e34\u0e16\u0e38\u0e19\u0e32\u0e22\u0e19",
	"July":"\u0e01\u0e23\u0e01\u0e0e\u0e32\u0e04\u0e21",
	"August":"\u0e2a\u0e34\u0e07\u0e2b\u0e32\u0e04\u0e21",
	"September":"\u0e01\u0e31\u0e19\u0e22\u0e32\u0e22\u0e19",
	"October":"\u0e15\u0e38\u0e25\u0e32\u0e04\u0e21",
	"November":"\u0e1e\u0e24\u0e28\u0e08\u0e34\u0e01\u0e32\u0e22\u0e19",
	"December":"\u0e18\u0e31\u0e19\u0e27\u0e32\u0e04\u0e21",
	// Image Upload Open
	"crm.label.browse.files":"\u0e40\u0e23\u0e35\u0e22\u0e01\u0e14\u0e39\u0e44\u0e1f\u0e25\u0e4c",//no i18n
	"crm.label.lowercase.or":"\u0e2b\u0e23\u0e37\u0e2d",//no i18n
	"crm.image.header":"\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e",//no i18n
	"crm.image.n.n":"\u0e2d\u0e31\u0e15\u0e23\u0e32\u0e2a\u0e48\u0e27\u0e19 N:N",//no i18n
	"crm.image.2.2":"\u0e2d\u0e31\u0e15\u0e23\u0e32\u0e2a\u0e48\u0e27\u0e19 2:2",//no i18n
	"crm.image.4.3":"\u0e2d\u0e31\u0e15\u0e23\u0e32\u0e2a\u0e48\u0e27\u0e19 4:3",//no i18n
	"crm.image.16.9":"\u0e2d\u0e31\u0e15\u0e23\u0e32\u0e2a\u0e48\u0e27\u0e19 16:9",//no i18n
	"crm.image.height.width.swap":"\u0e2a\u0e25\u0e31\u0e1a\u0e04\u0e27\u0e32\u0e21\u0e2a\u0e39\u0e07\u0e41\u0e25\u0e30\u0e04\u0e27\u0e32\u0e21\u0e01\u0e27\u0e49\u0e32\u0e07",//no i18n
	"crm.image.rotate.image":"\u0e2b\u0e21\u0e38\u0e19",//no i18n
	"crm.label.lowercase.of":"\u0e02\u0e2d\u0e07",//no i18n
	"crm.image.empty.name.placeholder" : "\u0e1b\u0e49\u0e2d\u0e19\u0e0a\u0e37\u0e48\u0e2d",
	"crm.image.crop.and.rotate" : "\u0e04\u0e23\u0e2d\u0e1a\u0e15\u0e31\u0e14\u0e41\u0e25\u0e30\u0e2b\u0e21\u0e38\u0e19",
	"crm.image.description" : "\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e04\u0e33\u0e2d\u0e18\u0e34\u0e1a\u0e32\u0e22...",
	"crm.image.actual.size" : "\u0e02\u0e19\u0e32\u0e14\u0e08\u0e23\u0e34\u0e07",
	"crm.image.reset" : "\u0e23\u0e35\u0e40\u0e0b\u0e47\u0e15",
	"crm.image.width.px" : "px",
	"crm.view.attachment.download":"\u0e14\u0e32\u0e27\u0e19\u0e4c\u0e42\u0e2b\u0e25\u0e14",//no i18n
	"crm.label.edit":"\u0e41\u0e01\u0e49\u0e44\u0e02",//no i18n
	"crm.label.close":"\u0e1b\u0e34\u0e14",//no i18n
	"crm.label.any":"\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e43\u0e14",
	"crm.image.description":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e04\u0e33\u0e2d\u0e18\u0e34\u0e1a\u0e32\u0e22...",//no i18n
	"crm.image.zoom.in":"\u0e0b\u0e39\u0e21\u0e40\u0e02\u0e49\u0e32",//no i18n
	"crm.image.zoom.out":"\u0e0b\u0e39\u0e21\u0e2d\u0e2d\u0e01",//no i18n
	"crm.label.lowercase.of":"\u0e02\u0e2d\u0e07",//no i18n
	"crm.image.desc.maxsize":"\u0e04\u0e33\u0e2d\u0e18\u0e34\u0e1a\u0e32\u0e22\u0e44\u0e21\u0e48\u0e04\u0e27\u0e23\u0e40\u0e01\u0e34\u0e19 255 \u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e29\u0e23",//no i18n
	"crm.image.name.maxsize":"\u0e0a\u0e37\u0e48\u0e2d\u0e44\u0e21\u0e48\u0e04\u0e27\u0e23\u0e40\u0e01\u0e34\u0e19 100 \u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e29\u0e23",//no i18n
	"crm.fileuploader.message.totalfilesizeexceeded":"\u0e02\u0e19\u0e32\u0e14\u0e44\u0e1f\u0e25\u0e4c\u0e23\u0e27\u0e21\u0e40\u0e01\u0e34\u0e19\u0e02\u0e35\u0e14\u0e08\u0e33\u0e01\u0e31\u0e14\u0e17\u0e35\u0e48\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15 {0}",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"\u0e04\u0e38\u0e13\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14\u0e44\u0e1f\u0e25\u0e4c\u0e44\u0e21\u0e48\u0e40\u0e01\u0e34\u0e19 {0} \u0e44\u0e1f\u0e25\u0e4c",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"\u0e04\u0e38\u0e13\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e41\u0e19\u0e1a\u0e44\u0e14\u0e49\u0e2a\u0e39\u0e07\u0e2a\u0e38\u0e14 {0} \u0e44\u0e1f\u0e25\u0e4c\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
	"crm.ImageuploadField.size.limit":"\u0e02\u0e19\u0e32\u0e14\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14\u0e40\u0e01\u0e34\u0e19\u0e02\u0e35\u0e14\u0e08\u0e33\u0e01\u0e31\u0e14 {0} MB \u0e17\u0e35\u0e48\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"\u0e04\u0e38\u0e13\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e\u0e44\u0e14\u0e49\u0e40\u0e1e\u0e35\u0e22\u0e07 1 \u0e23\u0e39\u0e1b\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
	"crm.image.crop.error":"\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e04\u0e23\u0e2d\u0e1a\u0e15\u0e31\u0e14\u0e20\u0e32\u0e1e\u0e44\u0e14\u0e49 \u0e42\u0e1b\u0e23\u0e14\u0e25\u0e2d\u0e07\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07!",//no i18n
	"crm.attach.here":"\u0e17\u0e35\u0e48\u0e19\u0e35\u0e48",//no i18n
	"crm.image.unsupported.corrupted.single":"\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e\u0e1a\u0e32\u0e07\u0e23\u0e39\u0e1b\u0e17\u0e35\u0e48\u0e04\u0e38\u0e13\u0e1e\u0e22\u0e32\u0e22\u0e32\u0e21\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14\u0e40\u0e2a\u0e35\u0e22\u0e2b\u0e32\u0e22",//no i18n
	"Browse":"\u0e40\u0e23\u0e35\u0e22\u0e01\u0e14\u0e39",//no i18n
	"crm.imageupload.failure":"\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e\u0e1a\u0e32\u0e07\u0e23\u0e39\u0e1b \u0e42\u0e1b\u0e23\u0e14\u0e25\u0e2d\u0e07\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07\u0e20\u0e32\u0e22\u0e2b\u0e25\u0e31\u0e07",//no i18n
	"zc.editor.width":"\u0e04\u0e27\u0e32\u0e21\u0e01\u0e27\u0e49\u0e32\u0e07",//no i18n
	"ze.editor.height":"\u0e04\u0e27\u0e32\u0e21\u0e2a\u0e39\u0e07",//no i18n
	"crm.label.delete":"\u0e25\u0e1a",//no i18n
	"crm.image.error.gif":"\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e04\u0e23\u0e2d\u0e1a\u0e15\u0e31\u0e14\u0e20\u0e32\u0e1e GIF",//no i18n
	"crm.fileuploader.message.morefiles":"\u0e2d\u0e35\u0e01 {0} \u0e44\u0e1f\u0e25\u0e4c",//no i18n
	"crm.fileuploader.message.invalidfileType1":"\u0e44\u0e1f\u0e25\u0e4c {0} \u0e44\u0e21\u0e48\u0e23\u0e2d\u0e07\u0e23\u0e31\u0e1a",//no i18n
	"crm.fileuploader.message.invalidfileType2":"\u0e44\u0e1f\u0e25\u0e4c {0} \u0e41\u0e25\u0e30 {1} \u0e44\u0e21\u0e48\u0e23\u0e2d\u0e07\u0e23\u0e31\u0e1a",//no i18n
	"crm.attach.upload.sizelimit":"\u0e02\u0e35\u0e14\u0e08\u0e33\u0e01\u0e31\u0e14\u0e02\u0e19\u0e32\u0e14\u0e42\u0e14\u0e22\u0e23\u0e27\u0e21\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//no i18n
	
	
	
	"crm.fileuploader.message.maxfilesexceeded" : "\u0e04\u0e38\u0e13\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14\u0e44\u0e1f\u0e25\u0e4c\u0e44\u0e21\u0e48\u0e40\u0e01\u0e34\u0e19 {0} \u0e44\u0e1f\u0e25\u0e4c",
"crm.file.upload.maxlength.exceeds" : "\u0e04\u0e38\u0e13\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14\u0e40\u0e1e\u0e35\u0e22\u0e07\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e44\u0e1f\u0e25\u0e4c\u0e43\u0e19 {0}.",


	"crm.label.add.tags":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e41\u0e17\u0e47\u0e01",//no i18n
	"crm.confirm.deassociate":"\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01\u0e01\u0e32\u0e23\u0e21\u0e2d\u0e1a\u0e2b\u0e21\u0e32\u0e22"//no i18n
}
